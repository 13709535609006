import React from "react";
import { Row, Col } from "antd";
import styles from "./mealview.module.css";
import {
  CalendarOutlined,
  ShoppingCartOutlined,
  LoginOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import FoodSidebar from "./FoodSidebar";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

var mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

function DashboardSidebar(props) {
  const [sidebarVisible, setFoodSidebar] = React.useState(false);

  return (
    <Col
      span={3}
      className={`${styles.sidebar} `}
      id="dashboardSidebar"
      style={{}}
    >
      <a>
        <img src="/logo.png" style={{ width: "160px" }} alt="" />
      </a>
      <h4 className="text-white mt-5 mb-5" style={{ fontSize: "18px" }}>
        <Link to="/dashboard/profile" className={styles.sidebarItem}>
          {props.user.email}
        </Link>
      </h4>
      <div
        className="mb-4"
        style={{ width: "30px", height: "2px", background: "#42454F" }}
      ></div>

      <div className="mb-5 mt-5">
        <Link to="/dashboard" className={styles.sidebarItem}>
          <CalendarOutlined />
          Meal Planner
        </Link>
        <Link to="/dashboard/groceries" className={styles.sidebarItem}>
          <ShoppingCartOutlined />
          Groceries
        </Link>
        <p
          className={`${styles.sidebarItem} ${styles.searchBtn}`}
          onClick={() => setFoodSidebar(true)}
        >
          <SearchOutlined /> Search Foods
        </p>

        <FoodSidebar
          visible={sidebarVisible}
          updateVisible={() => setFoodSidebar(false)}
        />
      </div>
      <div
        className="mb-4"
        style={{ width: "30px", height: "2px", background: "#42454F" }}
      ></div>

      <div>
        <p
          className={styles.sidebarItem}
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.localStorage.removeItem("authToken");
            window.location.href = "/";
          }}
        >
          <LoginOutlined /> Logout
        </p>
      </div>
    </Col>
  );
}

export default connect(mapStateToProps, null)(DashboardSidebar);
