import React, { Component } from "react";
import MultiMealBox from "./MultiMealBox";

export default class MultiMeal extends Component {
  render() {
    return (
      <div className="row mx-0">
        {this.props.data.map((item) => (
          <MultiMealBox
            mealrecipes={item.recipes}
            data={{ date: item.date }}
            generateMeal={this.props.generateMeal}
            generateMealDay={this.props.generateMealDay}
          />
        ))}
      </div>
    );
  }
}
