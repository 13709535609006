import React, { Component } from "react";
import { Row, Col, message } from "antd";
import styles from "./mealview.module.css";
import apiRequest from "../data/clientRequest";
import SingleMeal from "./SingleMeal";
import Slider from "react-slick";
import DashboardHeader from "./DashboardHeader";
import "./mealview.css";
import WeeklyMeal from "./WeeklyMeal";
import MultiMeal from "./MultiMeal";
import { connect } from "react-redux";
import ACTIONS from "../redux/actions/recipe";
import MEALACTION from "../redux/actions/meals";
import moment from "moment";
import _ from "lodash";

var mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    meals: state.meal.items,
    recipes: state.recipe.items,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setCalculateMeal: (item) => dispatch(MEALACTION.setCalculateMeal(item)),
  setCalculateMealDay: (item) => dispatch(MEALACTION.setCalculateMealDay(item)),
});

class MealView extends Component {
  state = {
    view: "single",
  };

  sliderWrapper = React.createRef();
  currentIndex = 0;

  getCurrentWeek() {
    var currentDate = moment();

    var weekStart = currentDate.clone().startOf("Week");
    var weekEnd = currentDate.clone().endOf("Week");

    var days = [];

    for (var i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, "days").format("MM DD YYYY"));
    }
    return days;
  }

  renderMultiView() {
    const { meals } = this.props;
    let newArr = [];

    for (let i = 0; i <= meals.length; i += 4) {
      newArr.push(meals.slice(i, i + 4));
    }

    newArr = newArr.filter((item) => item.length > 0);

    const currentIndex = newArr.findIndex((item) => {
      item.findIndex((ite) =>
        moment(ite.date).isSame(moment().format("MM DD YYYY"))
      );
    });
    this.currentIndex = currentIndex;
    console.log(newArr);
    return (
      <Slider
        ref={this.sliderWrapper}
        {...{
          dots: false,
          arrows: true,
          infinite: false,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: currentIndex,
          responsive: [
            {
              breakpoint: 770,
              settings: {
                arrows: false,
              },
            },
          ],
        }}
      >
        {newArr.map((item, index) => (
          <MultiMeal
            key={index}
            data={item}
            // mealrecipes={item.recipes}
            generateMeal={this.generateMeal.bind(this)}
            generateMealDay={this.generateMealDay.bind(this)}
          />
        ))}
      </Slider>
    );
  }

  renderWeekView() {
    const { meals } = this.props;
    let newArr = [];
    for (let i = 0; i <= meals.length; i += 16) {
      newArr.push(meals.slice(i, i + 16));
    }

    let parentArr = [];

    for (let i = 0; i < newArr.length; i++) {
      let tempArr = [];
      for (let j = 0; j < newArr[i].length; j += 4) {
        tempArr.push(newArr[i].slice(j, j + 4));
      }
      if (tempArr.length > 0) parentArr.push(tempArr);
    }

    const currentIndex = parentArr.findIndex((item) => {
      item.findIndex((ite) =>
        ite.findIndex((itemm) =>
          moment(itemm.date).isSame(moment().format("MM DD YYYY"))
        )
      );
    });

    this.currentIndex = currentIndex;
    return (
      <Slider
        ref={this.sliderWrapper}
        {...{
          dots: false,
          arrows: true,
          infinite: false,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: currentIndex,
        }}
      >
        {parentArr.map((item) => {
          return (
            <div>
              {item.map((ite, index) => {
                return <WeeklyMeal key={index} data={ite} />;
              })}
            </div>
          );
        })}
      </Slider>
    );
  }

  renderSingleView() {
    const { meals } = this.props;

    if (meals.length > 0) {
      if (this.state.view == "single") {
        var currentIndex = meals.findIndex((item) => {
          if (
            moment(item.date).format("MM DD YYYY") ==
            moment().format("MM DD YYYY")
          )
            return true;
        });
      }
    }
    this.currentIndex = currentIndex;
    return (
      <Slider
        ref={this.sliderWrapper}
        {...{
          dots: false,
          arrows: true,
          infinite: false,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: currentIndex,
          responsive: [
            {
              breakpoint: 770,
              settings: {
                arrows: false,
              },
            },
          ],
        }}
      >
        {meals.map((item, index) => (
          <SingleMeal
            key={index}
            data={item}
            mealrecipes={item.recipes}
            generateMeal={this.generateMeal.bind(this)}
            generateMealDay={this.generateMealDay.bind(this)}
          />
        ))}
      </Slider>
    );
  }

  renderContent() {
    if (this.props.meals.length == 0) return;
    var content =
      this.state.view == "single"
        ? this.renderSingleView()
        : this.state.view == "multi"
        ? this.renderMultiView()
        : this.renderWeekView();

    return content;
  }

  async generateMeal(payload) {
    let items = JSON.parse(JSON.stringify(this.props.meals));
    const profile = this.props.user.profile;
    const { date, type } = payload;
    const { fats, carbs, proteins } = profile;
    const relatedType = this.props.recipes.filter(
      (item) =>
        item.type.includes(type) &&
        this.props.user.profile.prefferedMeals.find((diet) =>
          item.diet.includes(diet)
        )
    );
    const foundIndex = items.findIndex(
      (item) => moment(item.date).format("MM DD YYYY") == date
    );
    let allRecipes = [...items[foundIndex]["recipes"]];
    allRecipes = allRecipes.map((item) => item.items);
    allRecipes = [].concat(...allRecipes);

    var whileCondition = true;
    if (relatedType.length > 0) {
      while (whileCondition) {
        var founditem =
          relatedType[Math.floor(Math.random() * relatedType.length)];

        if (founditem && !allRecipes.find((item) => item.id == founditem.id))
          whileCondition = false;
      }
    } else {
      var founditem = relatedType[0];
    }

    if (founditem) {
      let foundType = items[foundIndex].recipes.find(
        (item) => item.type == type
      );
      if (foundType) {
        items[foundIndex].recipes = items[foundIndex].recipes.map((ite) => {
          if (ite.type == type) {
            ite.items = [
              { ...founditem, servings: 1, origRecipe: founditem.servings },
            ];
          }
          return ite;
        });
      } else {
        items[foundIndex]["recipes"].push({
          items: [
            { ...founditem, servings: 1, origRecipe: founditem.servings },
          ],
          type,
        });
      }
    }
    let tempItem = { ...items[foundIndex] };
    tempItem.date = moment(tempItem.date).format("MM DD YYYY");
    tempItem["recipes"] = tempItem.recipes.map((item) => {
      let temp = {
        ...item,
        recipeType: item.type,
      };
      return temp;
    });

    await apiRequest({
      url: "/mealplan",
      method: "POST",
      data: {
        mealPlan: tempItem,
      },
    }).then((res) => {
      this.props.setCalculateMeal([...items]);
      message.success("Meal Plan updated");
    });

    return true;
  }

  async generateMealDay(payload) {
    let items = [...this.props.meals];

    const { date } = payload;
    const profile = this.props.user.profile;
    let mealTypes = [...payload.types];
    const { fats, carbs, proteins, noOfMeals } = profile;
    const foundIndex = items.findIndex(
      (item) => moment(item.date).format("MM DD YYYY") == date
    );

    let allRecipes = [...items[foundIndex]["recipes"]];
    allRecipes = allRecipes.map((item) => item.items);
    allRecipes = [].concat(...allRecipes);
    items[foundIndex]["recipes"] = [];
    for (let i = 0; i < mealTypes.length; i++) {
      const relatedType = this.props.recipes.filter(
        (item) =>
          item.type.includes(mealTypes[i]) &&
          this.props.user.profile.prefferedMeals.find((diet) =>
            item.diet.includes(diet)
          )
      );

      if (relatedType.length > 1) {
        var whileCondition = true;
        while (whileCondition) {
          var founditem =
            relatedType[Math.floor(Math.random() * relatedType.length)];

          if (founditem && !allRecipes.find((item) => item.id == founditem.id))
            whileCondition = false;
        }
      } else {
        var founditem = relatedType[0];
      }

      if (founditem) {
        allRecipes.push(founditem);
        items[foundIndex]["recipes"].push({
          items: [
            { ...founditem, servings: 1, origRecipe: founditem.servings },
          ],
          type: mealTypes[i],
        });
      }
    }

    let tempItem = { ...items[foundIndex] };
    tempItem.date = moment(tempItem.date).format("MM DD YYYY");
    tempItem["recipes"] = tempItem.recipes.map((item) => {
      let temp = {
        ...item,
        recipeType: item.type,
      };
      return temp;
    });

    await apiRequest({
      url: "/mealplan",
      method: "POST",
      data: {
        mealPlan: tempItem,
      },
    }).then((res) => {
      this.props.setCalculateMealDay([...items]);
      message.success("Meal Plan updated");
    });

    return true;
  }

  calenderChange(date = moment().format("MM DD YYYY")) {
    if (this.state.view == "single")
      var foundDay = this.sliderWrapper.current.props.children.find(
        (item) => moment(item.props.data.date).format("MM DD YYYY") == date
      );
    else {
      var foundDay = this.sliderWrapper.current.props.children.find((item) =>
        item.props.data.find(
          (ite) => moment(ite.date).format("MM DD YYYY") == date
        )
      );
    }
    if (foundDay) {
      setTimeout(() => {
        this.sliderWrapper.current.slickGoTo(foundDay.key);
      }, 1000);
    } else {
      message.error("Selected date is outside of the current meal plan range.");
    }
  }

  render() {
    return (
      <>
        <DashboardHeader
          view={this.state.view}
          fetchMealPlan={this.props.fetchMealPlan}
          onChange={(view) => {
            this.setState({ view }, () => {
              this.calenderChange();
            });
          }}
          onClickNext={() => this.sliderWrapper.current.slickNext()}
          onClickPrev={() => this.sliderWrapper.current.slickPrev()}
          onCalenderChange={(date) => {
            this.calenderChange(date);
          }}
          onClickToday={() =>
            this.sliderWrapper.current.slickGoTo(this.currentIndex)
          }
        />

        <div className={` ${styles.dayContainer}  singleDayContainer  pb-5`}>
          {this.renderContent()}
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MealView);
