var Types = {
  setMeals: "setMeals",
  calculateMeal: "calculateMeal",
  calculateMealDay: "calculateMealDay",
  addRecipe: "addRecipe",
  setServing: "serServing",
  setGroceries: "setGroceries",
  removeMeal: "removeMeal",
  SET_MY_MEAL_PLANS: "SET_MY_MEAL_PLANS",
};
// actions
var setMeals = (cond) => ({
  type: Types.setMeals,
  payload: cond,
});

var setCalculateMeal = (cond) => ({
  type: Types.calculateMeal,
  payload: cond,
});

var setCalculateMealDay = (cond) => ({
  type: Types.calculateMealDay,
  payload: cond,
});

var addRecipe = (cond) => ({
  type: Types.addRecipe,
  payload: cond,
});

var setServing = (cond) => ({
  type: Types.setServing,
  payload: cond,
});

var setGroceries = (cond) => ({
  type: Types.setGroceries,
  payload: cond,
});

var setRemoveMeal = (cond) => ({
  type: Types.removeMeal,
  payload: cond,
});

var setMyMealPLans = (cond) => ({
  type: Types.SET_MY_MEAL_PLANS,
  payload: cond,
});

export default {
  setGroceries,
  setRemoveMeal,
  setServing,
  addRecipe,
  setMeals,
  setCalculateMeal,
  setCalculateMealDay,
  Types,
  setMyMealPLans,
};
