import React, { Component } from "react";
import "./style.css";
import "./normalize.css";

export default class header extends Component {
  render() {
    return (
      <>
        <header>
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="eatThisLogo">
                  <a href="#" class="navbar-brand">
                    <img
                      src="/loginlogo.png"
                      style={{ width: "200px" }}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}
