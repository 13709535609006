import ACTIONS from "../actions/auth";
import _ from "lodash";
import axios from "axios";

var defaultState = {
  user: null,
  onboarding: null,
  linkAccount: {
    id: "",
    token: "",
  },
};

var authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ACTIONS.Types.setUser: {
      let newState = Object.assign({}, state);
      newState.user = action.payload;
      return newState;
    }
    case ACTIONS.Types.linkAccount: {
      let newState = Object.assign({}, state);
      newState.linkAccount = action.payload;
      return newState;
    }
    case ACTIONS.Types.setOnboarding: {
      let newState = Object.assign({}, state);
      newState.onboarding = action.payload;
      return newState;
    }
    default:
      return state;
  }
};
export default authReducer;
