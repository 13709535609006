import React, { Component } from "react";
import MealBox from "./MealBox";
import SingleDayHead from "./SingleDayHead";
import MealTotals from "./MealTotals";
import ACTIONS from "../redux/actions/meals";
import { connect } from "react-redux";
import moment from "moment";

var mapStateToProps = (state) => {
  return {
    user: state.auth.user.profile,
    mealTypes: state.meal.types,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setCalculateMeal: (item) => dispatch(ACTIONS.setCalculateMeal(item)),
  setCalculateMealDay: (item) => dispatch(ACTIONS.setCalculateMealDay(item)),
});
class SingleMeal extends Component {
  generateMeal = async (type) => {
    await this.props.generateMeal({
      date: moment(this.props.data.date).format("MM DD YYYY"),
      type,
    });
    return true;
  };

  generateMealDay = async () => {
    var mealTypes =
      this.props.user.noOfMeals == 2
        ? this.props.user.typeOfMeals
        : this.props.mealTypes;

    let haveRecipes = this.props.mealrecipes.filter(
      (ite) => ite.items.length > 0
    );
    haveRecipes = haveRecipes.map((item) => item.type);
    if (
      haveRecipes.includes("OMAD") ||
      (this.props.user.noOfMeals == 1 && haveRecipes.length == 0)
    )
      haveRecipes = new Set(["OMAD"]);
    else haveRecipes = new Set([...mealTypes, ...haveRecipes]);

    const hasBread = this.props.user.bread;
    const hasDessert = this.props.user.dessert;

    if (hasBread) haveRecipes.add("Bread");
    if (hasDessert) haveRecipes.add("Dessert");
    haveRecipes.add("AFI");

    haveRecipes = Array.from(haveRecipes);

    await this.props.generateMealDay({
      date: moment(this.props.data.date).format("MM DD YYYY"),
      types: haveRecipes,
    });

    return true;
  };

  renderMeals() {
    let mealArr = [];

    var mealTypes =
      this.props.user.noOfMeals == 2
        ? this.props.user.typeOfMeals
        : this.props.mealTypes;

    let haveRecipes = this.props.mealrecipes.filter(
      (ite) => ite.items.length > 0
    );

    haveRecipes = haveRecipes.map((item) => item.type);
    haveRecipes = haveRecipes.filter((item) => item !== "Bread");
    haveRecipes = haveRecipes.filter((item) => item !== "Dessert");

    const hasBread = this.props.user.bread;
    const hasDessert = this.props.user.dessert;

    if (
      haveRecipes.includes("OMAD") ||
      (this.props.user.noOfMeals == 1 && haveRecipes.length == 0)
    )
      haveRecipes = new Set(["OMAD"]);
    else haveRecipes = new Set([...mealTypes, ...haveRecipes]);

    haveRecipes = Array.from(haveRecipes);

    var tempArr = [];
    if (haveRecipes.includes("OMAD")) tempArr.push("OMAD");
    if (haveRecipes.includes("Breakfast")) tempArr.push("Breakfast");
    if (haveRecipes.includes("Lunch")) tempArr.push("Lunch");
    if (haveRecipes.includes("Dinner")) tempArr.push("Dinner");
    if (hasBread) tempArr.push("Bread");
    if (hasDessert) tempArr.push("Dessert");

    tempArr.push("AFI");
    haveRecipes = tempArr;

    for (let i = 0; i < haveRecipes.length; i++) {
      mealArr.push(
        <MealBox
          type={haveRecipes[i]}
          onClickGenerate={this.generateMeal}
          date={this.props.data.date}
          meals={this.props.mealrecipes.filter((item) =>
            item.type.includes(haveRecipes[i])
          )}
        />
      );
    }

    return mealArr;
  }

  render() {
    return (
      <div className="container mx-auto pt-4">
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <SingleDayHead
                date={this.props.data.date}
                onGenerateClick={this.generateMealDay}
              />
              {this.renderMeals()}
            </div>
          </div>
          <MealTotals recipes={[...this.props.mealrecipes]} />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleMeal);
