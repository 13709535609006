var Types = {
  setRecipes: "setRecipes",
};
// actions
var setRecipes = (cond) => ({
  type: Types.setRecipes,
  payload: cond,
});

export default {
  setRecipes,
  Types,
};
