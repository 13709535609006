import React, { Component } from "react";
import MealView from "./MealView";
import GroceryList from "./GroceryList";
import DashboardSidebar from "./DashboardSidebar";
import { Row, Col, message } from "antd";
import RestrictedRoute from "./RestrictedRoute";
import ProfilePage from "./ProfilePage";
import { withRouter, Route, Switch } from "react-router-dom";
import MEALACTION from "../redux/actions/meals";
import ACTIONS from "../redux/actions/recipe";
import { connect } from "react-redux";
import apiRequest from "../data/clientRequest";

var mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    meals: state.meal.items,
    recipes: state.recipe.items,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setRecipes: (item) => dispatch(ACTIONS.setRecipes(item)),
  setMeals: (item) => dispatch(MEALACTION.setMeals(item)),
  setGroceries: (item) => dispatch(MEALACTION.setGroceries(item)),
  setCalculateMeal: (item) => dispatch(MEALACTION.setCalculateMeal(item)),
  setCalculateMealDay: (item) => dispatch(MEALACTION.setCalculateMealDay(item)),
  setMyMealPLans: (item) => dispatch(MEALACTION.setMyMealPLans(item)),
});

class DashboardContainer extends Component {
  componentDidMount() {
    this.fetchMealPlan();

    apiRequest({
      url: "/recipes",
      method: "GET",
    })
      .then((res) => {
        this.props.setRecipes(res.data);
      })
      .catch((err) => {
        message.error(err.data);
      });
  }

  fetchMealPlan = () => {
    apiRequest({ url: "/user-recipes", method: "GET" }).then((res) => {
      this.props.setMyMealPLans(res.data.data);
    });

    apiRequest({
      url: "/mealplans",
      method: "GET",
    }).then((res) => {
      if (res.data.length > 0) {
        let modData = res.data.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );

        modData = modData.map((item) => {
          item.recipes = item.recipes.map((item) => {
            item["items"] = item["items"].filter(
              (ite) => ite.recipe !== null || ite.userrecipe !== null
            );
            return item;
          });
          item.recipes = item.recipes.map((recp) => {
            if (recp["recipeType"]) console.log("inside");
            recp.items = recp.items.filter((ite) =>
              ite.recipe ? ite.recipe : ite.userrecipe
            );
            recp.items = recp.items.map((indrec) => {
              let r = indrec.recipe ? indrec.recipe : indrec.userrecipe;
              let secTemp = {
                ...r,
                fats: r.fats * indrec.servings,
                calories: r.calories * indrec.servings,
                proteins: r.proteins * indrec.servings,
                servings: indrec.servings,
                origRecipe: r.servings,
                carbs: r.carbs * indrec.servings,
                sugaralcohol: r.sugaralcohol * indrec.servings,
                fiber: r.fiber * indrec.servings,
                netCarbs: r.netCarbs * r.servings,
                leftOver: indrec["leftOver"],
                ingredients: r.ingredients
                  ? r.ingredients.map((item) => {
                      let tempObj = {
                        ...item,
                        amount: item.amount * indrec.servings,
                      };
                      return tempObj;
                    })
                  : [],
              };

              return secTemp;
            });
            let tempObj = {
              ...recp,
              type: recp.recipeType,
            };

            return tempObj;
          });
          item.date = item.date.match(/^.*?(?=T)/gi)[0];
          return item;
        });

        this.props.setMeals(modData);
        this.props.setGroceries(modData);

        message.success("Meal Plan Fetched Successfully");
      }
    });
  };

  render() {
    return (
      <Row style={{ minHeight: "100vh" }}>
        {/* <DashboardSidebar /> */}
        <Col span={24}>
          <Switch>
            <RestrictedRoute
              exact
              path="/dashboard"
              extraProp={{ fetchMealPlan: this.fetchMealPlan }}
              component={MealView}
            />
            <RestrictedRoute
              path="/dashboard/groceries"
              component={GroceryList}
            />
            <RestrictedRoute
              path="/dashboard/profile"
              component={ProfilePage}
            />
          </Switch>
        </Col>
      </Row>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
