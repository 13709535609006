import React, { Component } from "react";
import Form from "./components/Onboard/form";
import "antd/dist/antd.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import "./components/style.css";
import Login from "./Containers/Login";
import { withRouter, Route, Switch } from "react-router-dom";
import MealView from "./components/MealView";
import apiRequest from "./data/clientRequest";
import { connect } from "react-redux";
import ACTIONS from "./redux/actions/auth";
import RestrictedRoute from "./components/RestrictedRoute";
import moment from "moment";
import DashboardContainer from "./components/DashboardContainer";
import { message } from "antd";
var mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setUser: (item) => dispatch(ACTIONS.setUser(item)),
  setOnboarding: (item) => dispatch(ACTIONS.setOnboarding(item)),
});
class App extends Component {
  constructor() {
    super();
    this.state = { isAuthenticated: false, user: null, token: "" };
  }

  componentWillMount() {
    const token = window.localStorage.getItem("authToken");
    if (token) {
      apiRequest({
        url: "/me",
        method: "GET",
      })
        .then((res) => {
          this.props.setUser(res.data);
          if (res.data.profile) this.props.history.push("/dashboard");
          else {
            this.props.setOnboarding(true);
            this.props.history.push("/onboard");
          }
        })
        .catch((err) => {
          message.error(
            "You must have an active membership to the Lean Warrior Lifestyle program to access this application.  Contact support."
          );

          this.props.history.push("/");
        });
    }
  }
  render() {
    return (
      <Switch>
        <Route path="/" exact component={Login} />
        <RestrictedRoute path="/dashboard" component={DashboardContainer} />
        <Route path="/onboard" component={Form} />
      </Switch>
    );
  }
}

const withRoute = withRouter(App);

export default connect(mapStateToProps, mapDispatchToProps)(withRoute);
