import React, { Component } from "react";
import WeeklyMealBox from "./WeeklyMealBox";
import MealBox from "./MealBox";
import moment from "moment";
export default class WeeklyMeal extends Component {
  render() {
    return (
      <>
        <div class="weeklyDay mt-4">
          <div class="dayHeader">
            <div class="dayName">
              <h2>
                {moment(this.props.data[0].date).format("dddd, Do")} -
                {moment(
                  this.props.data[this.props.data.length - 1].date
                ).format("dddd, Do")}
                <span
                  style={{ color: "black", fontSize: ".7em" }}
                  className="d-inline-block ml-3"
                >
                  893 Calories
                </span>{" "}
              </h2>
            </div>
          </div>
          <div class="d-flex weeklyMealContainer">
            <div className="col-md-3 p-0">
              <MealBox />
            </div>
            <div className="col-md-3 p-0">
              <MealBox />
            </div>
            <div className="col-md-3 p-0">
              <MealBox />
            </div>
            <div className="col-md-3 p-0">
              <MealBox />
            </div>
          </div>
        </div>
      </>
    );
  }
}
