import React, { Component } from "react";
import { connect } from "react-redux";
import MEALACTION from "../redux/actions/meals";
import styles from "./mealview.module.css";
import Slider from "react-slick";
import _ from "lodash";
import { Button } from "antd";
import GrocBox from "./GroceryBox";
import moment from "moment";
import DashboardHeader from "./DashboardHeader";
import ACTIONS from "../redux/actions/meals";

var mapStateToProps = (state) => {
  return {
    groceries: state.meal.groceries,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setGroceries: (item) => dispatch(ACTIONS.setGroceries(item)),
});

class GroceryList extends Component {
  componentWillMount() {
    this.props.setGroceries();
  }

  renderContent() {
    let grocers = [...this.props.groceries];
    grocers = grocers.reverse();
    const content = grocers.map((item) => {
      let grouped = _.groupBy(item.Recipes, "category");
      let groupedKeys = Object.keys(grouped);

      let order = [
        "Fruits",
        "Vegetables",
        "Protein/Meats",
        "Seafood/Fish",
        "Pantry/Staples",
        "Dairy",
        "Supplements",
        "Other",
        "Dependent Recipes",
      ];

      order = order.filter((item) => groupedKeys.includes(item));
      return (
        <div className="container mx-auto pt-4 d-block">
          <div className="row">
            <div className="col-md-12">
              <div className="row px-3">
                {" "}
                <h1 style={{ fontSize: "24px" }}>
                  {/* {moment(item.dates[0]).format("MMM, DD YYYY")} -{" "}
                  {moment(item.dates[item.dates.length - 1]).format(
                    "MMM, DD YYYY"
                  )} */}
                  {item.dates[0]} - {item.dates[item.dates.length - 1]}
                </h1>
              </div>
              <div className="row mt-2">
                {order.map(function (key, index) {
                  let nestedGroup = _.groupBy(grouped[key], "name");
                  let groupKeys = Object.keys(nestedGroup);

                  groupKeys = groupKeys.sort();

                  var subCats = groupKeys.map(function (key, index) {
                    var totalAmount = 0;

                    nestedGroup[key].map((amp) => (totalAmount += amp.amount));

                    let tempObj = {
                      name: key,
                      image: nestedGroup[key][0]["image"],
                      amount: totalAmount,
                      unit: nestedGroup[key][0]["unitOfMeasure"],
                    };
                    return <GrocBox item={tempObj} />;
                  });
                  return (
                    <div className="col-md-6 mt-4">
                      <p
                        className={`${styles.breakHeading}`}
                        style={{
                          background: "#fa52af",
                          textAlign: "center",
                          padding: "0.6em",
                          color: "white",
                        }}
                      >
                        {key}
                      </p>
                      {subCats}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    });

    return content;
  }

  PrintElem(elem) {
    document.getElementById("dashboardSidebar").style.display = "none";
    // var mywindow = window.open("", "PRINT", "height=400,width=600");

    // mywindow.document.write(
    //   "<html><head><title>" + document.title + "</title>"
    // );
    // mywindow.document.write("</head><body >");
    // mywindow.document.write(document.getElementById(elem).innerHTML);
    // mywindow.document.write("</body></html>");

    // mywindow.document.close(); // necessary for IE >= 10
    // mywindow.focus(); // necessary for IE >= 10*/

    // mywindow.print();
    // mywindow.close();

    // document.getElementById("dashboardSidebar").style.display = "block";

    return true;
  }

  render() {
    return (
      <>
        <DashboardHeader />
        <div
          className={` mt-0 pt-5 ${styles.dayContainer}  singleDayContainer h-100 `}
          id="mealContainer"
          ref={(el) => (this.componentRef = el)}
        >
          <Button
            size="large"
            className="ml-5"
            type="primary"
            id="printBtn"
            onClick={() => {
              // createNewMealPlan();
              // document.getElementById("dashboardSidebar").style.display =
              //   "none";
              document.getElementById("printBtn").style.display = "none";
              document.getElementById("printLogo").style.display = "block";

              window.print();
              // document.getElementById("dashboardSidebar").style.display =
              //   "block";
              document.getElementById("printBtn").style.display = "block";
              document.getElementById("printLogo").style.display = "none";

              // this.PrintElem("mealContainer");
            }}
          >
            Print
          </Button>
          <img
            src="/loginlogo.png"
            style={{ width: "270px", display: "none" }}
            id="printLogo"
            alt=""
          />

          {this.renderContent()}
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroceryList);
