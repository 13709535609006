import React, { Component } from "react";
import { Form, Input, Button, Checkbox, Radio, Select } from "antd";
import { Row, Col } from "antd";
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
export default class step1 extends Component {
  state = {
    unit: "imperial",
    // gender: "male",
    activityLevel: "",
  };

  handleSizeChange = (e) => {
    this.setState({ unit: e.target.value });
  };
  // handleGenderChange = (e) => {
  //   this.setState({ gender: e.target.value });
  // };
  onFinish = (values) => {
    this.props.onNext({
      ...values,
      unit: this.state.unit,
    });
  };
  render() {
    return (
      <>
        <div class="container page-content">
          <div class="signup_section_header col-12 text-center">
            <h2 class="display-6">Tell us about yourself</h2>
            <p class="mb-0">
              This info lets us estimate your nutrition requirements for each
              day.
            </p>
          </div>
          <div class="step1">
            <Form
              {...layout}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={this.onFinish}
            >
              <h2 class="formHeading">Your profile</h2>

              <Row className="mb-4" align="middle">
                <Col span={8} className="text-right">
                  <label for="units-radio" className="mb-0">
                    Preferred units: &nbsp;
                  </label>
                </Col>
                <Col span={12}>
                  <Radio.Group
                    value={this.state.unit}
                    onChange={this.handleSizeChange}
                    size="large"
                  >
                    <Radio.Button
                      style={{ width: "160px", textAlign: "center" }}
                      value="imperial"
                    >
                      Imperial
                    </Radio.Button>
                    <Radio.Button
                      style={{ width: "160px", textAlign: "center" }}
                      value="metric"
                    >
                      Metric
                    </Radio.Button>
                  </Radio.Group>
                </Col>
              </Row>

              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please input your name!" }]}
              >
                <Input size="large" />
              </Form.Item>

              <Form.Item label="Height" style={{ marginBottom: 0 }}>
                <Form.Item
                  name="height"
                  rules={[
                    {
                      required: true,
                      message: "Please input your height!",
                    },
                  ]}
                  style={{ display: "inline-block", width: "calc(50% - 8px)" }}
                >
                  <Input
                    size="large"
                    suffix={this.state.unit == "imperial" ? "ft" : "cm"}
                    placeholder=""
                  />
                </Form.Item>
                {this.state.unit == "imperial" ? (
                  <Form.Item
                    name="hin"
                    rules={[
                      { required: true, message: "Please input your height!" },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      margin: "0 8px",
                    }}
                  >
                    <Input
                      size="large"
                      suffix={this.state.unit == "imperial" ? "in" : ""}
                      placeholder=""
                    />
                  </Form.Item>
                ) : (
                  ""
                )}
              </Form.Item>

              <Form.Item
                label="Weight"
                name="weight"
                rules={[
                  { required: true, message: "Please input your weight!" },
                ]}
              >
                <Input
                  suffix={this.state.unit == "imperial" ? "lbs" : "kgs"}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label="Age"
                name="age"
                rules={[{ required: true, message: "Please input your age!" }]}
              >
                <Input suffix={"years"} size="large" />
              </Form.Item>

              <Form.Item
                name="activityLevel"
                label="Activity Level"
                rules={[{ required: true, message: "Select Activity level" }]}
                extra={
                  this.state.activityLevel == "Sedentary"
                    ? "(little to no exercise)"
                    : this.state.activityLevel == "Moderately Active"
                    ? "(30 mins. or more exercise 3 times a week)"
                    : this.state.activityLevel == "Active"
                    ? "(30 mins. or more exercise greater than 4+ times a week)"
                    : ""
                }
              >
                <Select
                  onChange={(val) => this.setState({ activityLevel: val })}
                >
                  <Option value="Sedentary">Sedentary</Option>
                  <Option value="Moderately Active">Moderately Active</Option>
                  <Option value="Active">Active</Option>
                </Select>
              </Form.Item>

              <div class="text-center">
                <button
                  type="submit"
                  class="btn btn-large btn-orange mt-4"
                  // onClick={() => this.props.onNext()}
                >
                  Next step →
                </button>
              </div>
            </Form>
          </div>
        </div>
      </>
    );
  }
}
