var Types = {
  setUser: "setUser",
  linkAccount: "linkAccount",
  setOnboarding: "setOnboarding",
};
// actions
var setUser = (cond) => ({
  type: Types.setUser,
  payload: cond,
});

var setLinkAccountCreds = (cond) => ({
  type: Types.linkAccount,
  payload: cond,
});

// actions
var setOnboarding = (cond) => ({
  type: Types.setOnboarding,
  payload: cond,
});

export default {
  setUser,
  setOnboarding,
  setLinkAccountCreds,
  Types,
};
