import React, { Component, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Input,
  Col,
  Select,
  Checkbox,
  Form,
  message,
} from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { DatePicker } from "antd";
import styles from "./mealview.module.css";
import { connect } from "react-redux";
import ACTIONS from "../redux/actions/meals";
import apiRequest from "../data/clientRequest";
import moment from "moment";
import MEALACTION from "../redux/actions/meals";
import { useDispatch } from "react-redux";

const { Option } = Select;

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 16 },
};
var mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    meals: state.meal.items,
  };
};

var mapDispatchToProps = (dispatch) => ({
  addRecipe: (item) => dispatch(ACTIONS.addRecipe(item)),
});

function PersonalMeal(props) {
  const { isEdit, selectedItem } = props;
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    form.resetFields();
    if (isEdit && selectedItem) {
      console.log("inside here");

      form.setFieldsValue({
        ...selectedItem,
      });
    }
  }, [props.visible, isEdit, selectedItem]);

  const handleFinish = async (data) => {
    setLoading(true);
    if (isEdit) {
      await apiRequest({
        url: `/user-recipes/${selectedItem._id}`,
        method: "PUT",
        data,
      })
        .then(async (response) => {
          props.changeVisibility(false);
          message.success("Meal Updated");
          setTimeout(() => {
            form.resetFields();
          }, 1000);
          apiRequest({ url: "/user-recipes", method: "GET" }).then((res) => {
            dispatch(MEALACTION.setMyMealPLans(res.data.data));
          });
        })
        .catch((e) => {
          message.error("Unable to update meal");
          props.changeVisibility(false);
          form.resetFields();
        });
    } else {
      await apiRequest({
        url: "/user-recipes",
        method: "POST",
        data,
      })
        .then(async (response) => {
          props.changeVisibility(false);
          message.success("New Meal Added");
          setTimeout(() => {
            form.resetFields();
          }, 1000);
          apiRequest({ url: "/user-recipes", method: "GET" }).then((res) => {
            dispatch(MEALACTION.setMyMealPLans(res.data.data));
          });
        })
        .catch((e) => {
          message.error("Unable to add new meal");
          props.changeVisibility(false);
          form.resetFields();
        });
    }
    setLoading(false);
  };

  const handleOk = (data) => {
    if (!loading) form.submit();
  };

  const handleCancel = () => {
    props.changeVisibility(false);
  };

  return (
    <Modal
      title="Add your personal Meal"
      visible={props.visible}
      onOk={handleOk}
      // onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleOk}
          style={{ display: "flex", alignItems: "center" }}
        >
          {" "}
          {loading ? (
            "loading"
          ) : (
            <>
              {" "}
              <PlusCircleOutlined style={{ fontSize: "1.2em" }} /> &nbsp;
              {isEdit ? "Update" : "Add"}
            </>
          )}
        </Button>,
      ]}
      className="foodPopup"
    >
      <div className="p-0">
        <Form
          {...layout}
          form={form}
          name="control-hooks"
          onFinish={handleFinish}
        >
          <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Col
              span={18}
              xs={24}
              md={9}
              className="mt-3 mt-md-0 modalInputContainer"
            >
              <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>

            <Col
              span={18}
              xs={24}
              md={9}
              className="mt-3 mt-md-0 modalInputContainer"
            >
              <Form.Item name="type" label="Type" rules={[{ required: true }]}>
                <Select
                  // style={{ width: "160px" }}
                  placeholder={"Select Meal Type"}
                  mode="multiple" // size="large"
                >
                  <Option value="Breakfast">Breakfast</Option>
                  <Option value="Lunch">Lunch</Option>
                  <Option value="Dinner">Dinner</Option>
                  <Option value="Dessert">Dessert</Option>
                  <Option value="Bread">Bread</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col
              span={18}
              xs={24}
              md={9}
              className="mt-3 mt-md-0 modalInputContainer"
            >
              <Form.Item name="unit" label="Unit" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col
              span={18}
              xs={24}
              md={9}
              className="mt-3 mt-md-0 modalInputContainer"
            >
              <Form.Item
                name="servings"
                label="Servings"
                rules={[{ required: true }]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col
              span={18}
              xs={24}
              md={9}
              className="mt-3 mt-md-0 modalInputContainer"
            >
              <Form.Item
                name="servingSize"
                label="Serving Size"
                rules={[{ required: true }]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>

            <Col
              span={18}
              xs={24}
              md={9}
              className="mt-3 mt-md-0 modalInputContainer"
            >
              <Form.Item
                name="calories"
                label="Calories"
                rules={[{ required: true }]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col
              span={18}
              xs={24}
              md={9}
              className="mt-3 mt-md-0 modalInputContainer"
            >
              <Form.Item name="fats" label="Fats" rules={[{ required: true }]}>
                <Input type="number" />
              </Form.Item>
            </Col>

            <Col
              span={18}
              xs={24}
              md={9}
              className="mt-3 mt-md-0 modalInputContainer"
            >
              <Form.Item
                name="carbs"
                label="Carbs"
                rules={[{ required: true }]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col
              span={18}
              xs={24}
              md={9}
              className="mt-3 mt-md-0 modalInputContainer"
            >
              <Form.Item
                name="fiber"
                label="Fiber"
                rules={[{ required: true }]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>

            <Col
              span={18}
              xs={24}
              md={9}
              className="mt-3 mt-md-0 modalInputContainer"
            >
              <Form.Item
                name="sugaralcohol"
                label="Sugar Alcohol"
                rules={[{ required: true }]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col
              span={18}
              xs={24}
              md={9}
              className="mt-3 mt-md-0 modalInputContainer"
            >
              <Form.Item
                name="proteins"
                label="Proteins"
                rules={[{ required: true }]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>

            <Col
              span={18}
              xs={24}
              md={9}
              className="mt-3 mt-md-0 modalInputContainer"
            >
              <Form.Item
                name="cupsofveggies"
                label="Cups Of Veggies"
                rules={[{ required: true }]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalMeal);
