import { combineReducers } from "redux";
import Auth from "./auth";
import Recipe from "./recipe";
import Meals from "./meals";
var reducers = combineReducers({
  auth: Auth,
  recipe: Recipe,
  meal: Meals,
});

export default reducers;
