import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./mealview.module.css";
import _ from "lodash";
import { Form, Input, message, Button, Checkbox, Radio, Select } from "antd";
import { Row, Col } from "antd";
import apiRequest from "../data/clientRequest";
import ACTIONS from "../redux/actions/auth";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import DashboardHeader from "./DashboardHeader";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};

var mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setUser: (item) => dispatch(ACTIONS.setUser(item)),
});

class ProfilePage extends Component {
  state = {
    unit: "imperial",
    noOfMeals: this.props.user.profile.noOfMeals,
    activityLevel: this.props.user.profile.activityLevel,
  };

  formRef = React.createRef();

  onFinish = (values) => {
    console.log(values);
    this.calculateNutrition(values);
  };
  handleSizeChange = (e) => {
    this.setState({ unit: e.target.value });
  };

  calculateNutrition = (values) => {
    const { hin, height, weight, age, activityLevel, noOfMeals } = values;
    if (this.state.unit == "imperial") {
      var origHeight = (parseInt(height) * 12 + parseInt(hin)) * 2.54;
      var origWeight = weight / 2.2046;
      // console.log(hin);
      // console.log(origHeight);
      console.log(origWeight);
    } else {
      var origHeight = height;
      var origWeight = weight;
    }
    var activity = 0;
    if (activityLevel === "Sedentary") {
      activity = 1;
    } else if (activityLevel === "Moderately Active") {
      activity = 1.2;
    } else if (activityLevel === "Active") {
      activity = 1.3;
    }
    var tmr = (10 * origWeight + 6.25 * origHeight - 5 * age - 161) * activity;

    
    if (noOfMeals == 1) {
      var fats = Math.round(((tmr * 0.7) / 9) * 0.8);
      var proteins = Math.round(((tmr * 0.2) / 4) * 0.8);
      var carbs = Math.round(((tmr * 0.05) / 4) * 0.8);
    } else if (noOfMeals == 2) {
      var fats = Math.round(((tmr * 0.7) / 9) * 0.9);
      var proteins = Math.round(((tmr * 0.2) / 4) * 0.9);
      var carbs = Math.round(((tmr * 0.05) / 4) * 0.9);
    } else if (noOfMeals == 3) {
      var fats = Math.round((tmr * 0.7) / 9);
      var proteins = Math.round((tmr * 0.2) / 4);
      var carbs = Math.round((tmr * 0.05) / 4);
    }

    apiRequest({
      url: "/profile",
      method: "POST",
      data: {
        ...values,
        fats,
        carbs,
        proteins,
        calories: tmr,
        weight: origWeight,
        height: origHeight,
        email: this.props.user.email,
      },
    })
      .then((res) => {
        message.success(res.data);
        apiRequest({
          url: "/me",
          method: "GET",
        }).then((resme) => {
          this.props.setUser(resme.data);
          this.props.history.push("/dashboard");
        });
      })
      .catch((err) => {
        message.error(err.data.msg);
      });
  };

  toFeet(n, type) {
    var realFeet = (n * 0.3937) / 12;
    var feet = Math.floor(realFeet);
    var inches = Math.round((realFeet - feet) * 12);
    if (type == "feet") return feet;
    else return inches;
  }

  render() {
    const {
      name,
      age,
      email,
      activityLevel,
      noOfMeals,
      height,
      weight,
      prefferedMeals,
      bread,
      dessert,
      typeOfMeals,
    } = this.props.user.profile;

    return (
      <>
        <DashboardHeader />
        <div
          className={` mt-0 pt-5 ${styles.dayContainer}  singleDayContainer h-100 `}
        >
          <div className="container mx-auto pt-4 d-block">
            <div className="row">
              <div className="col-md-12">
                <div class="step1">
                  <Form
                    {...layout}
                    ref={this.formRef}
                    name="basic"
                    initialValues={{
                      remember: true,
                      name,
                      UserEmail: this.props.user.email,
                      age,
                      hin: this.toFeet(height, "inches"),
                      height: this.toFeet(height, "feet"),
                      weight: parseInt(weight * 2.20462262185),
                      activityLevel,
                      noOfMeals,
                      prefferedMeals,
                      bread,
                      dessert,
                      typeOfMeals,
                    }}
                    onFinish={this.onFinish}
                  >
                    <h2 class="formHeading text-center">Your profile</h2>

                    <Row className="mb-4" align="middle">
                      <Col span={8} className="text-right">
                        <label for="units-radio" className="mb-0">
                          Preferred units: &nbsp;
                        </label>
                      </Col>
                      <Col span={12}>
                        <Radio.Group
                          value={this.state.unit}
                          onChange={this.handleSizeChange}
                          size="large"
                        >
                          <Radio.Button
                            style={{ width: "160px", textAlign: "center" }}
                            value="imperial"
                          >
                            Imperial
                          </Radio.Button>
                          <Radio.Button
                            style={{ width: "160px", textAlign: "center" }}
                            value="metric"
                          >
                            Metric
                          </Radio.Button>
                        </Radio.Group>
                      </Col>
                    </Row>

                    <Form.Item label="Email" name="UserEmail" disabled>
                      <Input size="large" disabled />
                    </Form.Item>

                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        { required: true, message: "Please input your name!" },
                      ]}
                    >
                      <Input size="large" />
                    </Form.Item>

                    {/* <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        { required: true, message: "Please input your email!" },
                      ]}
                    >
                      <Input size="large" />
                    </Form.Item> */}
                    <Form.Item label="Height" style={{ marginBottom: 0 }}>
                      <Form.Item
                        name="height"
                        rules={[
                          {
                            required: true,
                            message: "Please input your height!",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 8px)",
                        }}
                      >
                        <Input
                          size="large"
                          suffix={this.state.unit == "imperial" ? "ft" : "cm"}
                          placeholder=""
                        />
                      </Form.Item>
                      {this.state.unit == "imperial" ? (
                        <Form.Item
                          name="hin"
                          rules={[
                            {
                              required: true,
                              message: "Please input your height!",
                            },
                          ]}
                          style={{
                            display: "inline-block",
                            width: "calc(50% - 8px)",
                            margin: "0 8px",
                          }}
                        >
                          <Input
                            size="large"
                            suffix={this.state.unit == "imperial" ? "in" : ""}
                            placeholder=""
                          />
                        </Form.Item>
                      ) : (
                        ""
                      )}
                    </Form.Item>

                    <Form.Item
                      label="Weight"
                      name="weight"
                      rules={[
                        {
                          required: true,
                          message: "Please input your weight!",
                        },
                      ]}
                    >
                      <Input
                        suffix={this.state.unit == "imperial" ? "lbs" : "kgs"}
                        size="large"
                      />
                    </Form.Item>
                    <Form.Item
                      label="Age"
                      name="age"
                      rules={[
                        { required: true, message: "Please input your age!" },
                      ]}
                    >
                      <Input suffix={"years"} size="large" />
                    </Form.Item>

                    <Form.Item
                      name="activityLevel"
                      label="Activity Level"
                      rules={[
                        { required: true, message: "Select Activity level" },
                      ]}
                      extra={
                        this.state.activityLevel == "Sedentary"
                          ? "(little to no exercise)"
                          : this.state.activityLevel == "Moderately Active"
                          ? "(30 mins. or more exercise 3 times a week)"
                          : this.state.activityLevel == "Active"
                          ? "(30 mins. or more exercise greater than 4+ times a week)"
                          : ""
                      }
                    >
                      <Select
                        onChange={(val) =>
                          this.setState({ activityLevel: val })
                        }
                      >
                        <Option value="Sedentary">Sedentary</Option>
                        <Option value="Moderately Active">
                          Moderately Active
                        </Option>
                        <Option value="Active">Active</Option>
                      </Select>
                    </Form.Item>

                    <Form.Item name="noOfMeals" label="No Of Meals">
                      <Select
                        size="large"
                        onChange={(val) => this.setState({ noOfMeals: val })}
                      >
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                      </Select>
                    </Form.Item>

                    {this.state.noOfMeals == 2 ? (
                      <Form.Item
                        name="typeOfMeals"
                        label="Preffered Meals (Select two)"
                        hasFeedback
                        rules={[
                          {
                            validator: (rule, value, callback) => {
                              if (value) {
                                if (value.length > 2) {
                                  callback("No more than 2 meals");
                                } else if (value.length < 2) {
                                  callback("Select 2 Preffered Meals");
                                } else if (value.length <= 2) {
                                  callback();
                                }
                              }
                              return;
                            },
                          },
                        ]}
                      >
                        <Select size="large" mode="multiple">
                          <Option value="Breakfast">Breakfast</Option>
                          <Option value="Lunch">Lunch</Option>
                          <Option value="Dinner">Dinner</Option>
                        </Select>
                      </Form.Item>
                    ) : (
                      ""
                    )}

                    <Form.Item
                      name="prefferedMeals"
                      label="Type Of Meals"
                      hasFeedback
                      rules={[
                        {
                          validator: (rule, value, callback) => {
                            if (value) {
                              if (value.length < 1) {
                                callback("Select a Prefferd Meal");
                              } else if (value.length >= 1) {
                                callback();
                              }
                            }
                            return;
                          },
                        },
                      ]}
                    >
                      <Select mode="multiple">
                        <Option value="Meat">Meat </Option>
                        <Option value="Vegan">Vegan</Option>
                        <Option value="Pescatarian">Pescatarian</Option>
                        <Option value="Vegetarian">Vegetarian</Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="bread"
                      label="Do you want to include Breads?"
                      valuePropName="checked"
                    >
                      <Checkbox></Checkbox>
                    </Form.Item>

                    <Form.Item
                      name="dessert"
                      label="Do you want to include Desserts?"
                      valuePropName="checked"
                    >
                      <Checkbox></Checkbox>
                    </Form.Item>

                    <div class="text-center">
                      <button
                        type="submit"
                        class="btn btn-large btn-orange mt-4"
                        // onClick={() => this.props.onNext()}
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
