import React, { Component } from "react";

export default class step3 extends Component {
  render() {
    return (
      <>
        <div class="container page-content">
          <div class="signup_back"></div>
          <div class="signup_section_header col-12 text-center">
            <h2 class="display-6">Nutrition Targets</h2>
            <p class="mb-0">
              Take a look at the nutrition targets we've estimated for you. In
              the meal planner, <b>Total Carbs</b> will reflect higher numbers
              than your target Carbs based on the foods selected. However, we
              track <b>Net Carbs</b> which are the <b>Total Carbs</b> minus
              Fiber and Sugar Alcohols. Therefore, your targets for{" "}
              <b>Net Carbs</b> as shown below is your actual daily target. You
              can adjust the targets later by clicking on your profile from the
              meal planner page.
            </p>
          </div>
          <div class="step3">
            <form class="">
              <div class="row">
                <div class="col-12">
                  <div class="nutrition-summary-text">
                    <div
                      class="d-flex align-items-center justify-content-between"
                      style={{
                        fontSize: "0.7em",
                        fontWeight: "400",
                      }}
                    >
                      <span class="">Calories</span>
                      <span class="" style={{ color: "#fa52af" }}>
                        {this.props.data.calories.toFixed(2)}g
                      </span>
                    </div>

                    <div
                      class=" d-flex align-items-center justify-content-between"
                      style={{
                        fontSize: "0.7em",
                        fontWeight: "400",
                      }}
                    >
                      <span class="">Fat</span>
                      <span class="" style={{ color: "#fa52af" }}>
                        {this.props.data.fats}g
                      </span>
                    </div>
                    <div
                      class=" d-flex align-items-center justify-content-between"
                      style={{
                        fontSize: "0.7em",
                        fontWeight: "400",
                      }}
                    >
                      <span class="">Protein</span>
                      <span class="" style={{ color: "#fa52af" }}>
                        {this.props.data.proteins}g
                      </span>
                    </div>
                    <div
                      class=" d-flex align-items-center justify-content-between"
                      style={{
                        fontSize: "0.7em",
                        fontWeight: "400",
                      }}
                    >
                      <span class="">Carbs (Total)</span>
                      <span class="" style={{ color: "#fa52af" }}>
                        {this.props.data.carbs}g
                      </span>
                    </div>
                    <div
                      class=" d-flex align-items-center justify-content-between"
                      style={{
                        fontSize: "0.7em",
                        fontWeight: "400",
                      }}
                    >
                      <span class="">Carbs (Net)</span>
                      <span class="" style={{ color: "#fa52af" }}>
                        {this.props.data.carbs}g
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="text-center">
            <button
              type="button"
              class="btn btn-large btn-orange"
              onClick={() => {
                this.props.onNext(this.props.data);
              }}
            >
              Finish Profile
            </button>
          </div>
        </div>
      </>
    );
  }
}
