import React, { Component } from "react";
import { Modal, Button, Row, Input, Col, Select } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { DatePicker, Checkbox, message } from "antd";
import styles from "./mealview.module.css";
import { connect } from "react-redux";
import ACTIONS from "../redux/actions/meals";
import moment from "moment";
const { Option } = Select;

var mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    meals: state.meal.items,
  };
};

var mapDispatchToProps = (dispatch) => ({
  addRecipe: (item) => dispatch(ACTIONS.addRecipe(item)),
});

class LeftOverModal extends Component {
  state = {
    checked: [],
  };

  handleOk = (e) => {
    if (!this.state.mealType) {
      message.error("Please select a meal type");
      return;
    }

    let noOfServing = parseInt(this.state.noOfServing);

    let selItem = Object.assign({}, this.props.selectedItem);
    selItem["leftOver"] = true;
    selItem["servings"] = 1;
    this.state.checked.map((item) => {
      this.props.addRecipe({
        item: selItem,
        date: item,
        type: this.state.mealType,
      });
    });
    this.props.changeVisibility(false);
  };

  handleCancel = (e) => {
    this.props.changeVisibility(false);
  };

  onChange = (e, val) => {
    if (e.target.checked) {
      this.setState((prev) => ({
        checked: [...prev.checked, val],
      }));
    } else {
      this.setState((prev) => ({
        checked: [...prev.checked.filter((item) => item !== val)],
      }));
    }
  };

  render() {
    const todayDate = this.props.meals.findIndex(
      (item) => item.date == moment().format("YYYY-MM-DD")
    );
    const mealDates = this.props.meals.slice(todayDate + 1, todayDate + 7);

    return (
      <Modal
        title="Create Leftovers for a Meal
        "
        visible={this.props.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={this.handleOk}
            style={{ display: "flex", alignItems: "center" }}
          >
            <PlusCircleOutlined style={{ fontSize: "1.2em" }} />
            Add
          </Button>,
        ]}
        className="foodPopup"
      >
        <div className="p-0">
          <Row style={{ alignItems: "center" }}>
            <Col span={4} offset={4} className="mr-4">
              <img
                src={`https://dashboard.leanwarriorweightloss.com/img/recipes/${
                  this.props.selectedItem ? this.props.selectedItem.image : ""
                }`}
                alt=""
                style={{
                  height: "100px",
                  width: "100px",
                  borderRadius: "15px",
                  border: "2px solid gainsboro",
                }}
              />
            </Col>
            <Col
              span={14}
              xs={24}
              md={14}
              className="mt-3 mt-md-0 modalInputContainer leftOverContainer"
            >
              <h2
                className={styles.foodSidebarItemText}
                style={{ display: "block" }}
              >
                {this.props.selectedItem ? this.props.selectedItem.name : ""}
              </h2>
              <Select
                style={{ width: "250px" }}
                placeholder={"Select Meal Type"}
                value={this.state.mealType}
                size="large"
                onChange={(val) => this.setState({ mealType: val })}
              >
                <Option value="Breakfast">Breakfast</Option>
                <Option value="Lunch">Lunch</Option>
                <Option value="Dinner">Dinner</Option>
                {this.props.user.profile.bread ? (
                  <Option value="Bread">Bread</Option>
                ) : (
                  ""
                )}
                {this.props.user.profile.dessert ? (
                  <Option value="Dessert">Dessert</Option>
                ) : (
                  ""
                )}

                <Option value="AFI">AFI</Option>
              </Select>
            </Col>
            <Col
              span={14}
              xs={24}
              md={14}
              className="mt-4  modalInputContainer leftOverContainer mx-auto"
            >
              {mealDates.map((item) => (
                <div className="d-flex justify-content-center">
                  <Checkbox
                    onChange={(e) =>
                      this.onChange(e, moment(item.date).format("MM DD YYYY"))
                    }
                  ></Checkbox>
                  <p
                    style={{
                      marginRight: "1em",
                      width: "200px",
                      marginLeft: "10px",
                    }}
                  >
                    {moment(item.date).format("dddd Do MMMM YYYY")}
                  </p>
                </div>
              ))}
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LeftOverModal);
