import React, { Component } from "react";
import styles from "./mealview.module.css";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { Tabs } from "antd";
import SidebarFoodItem from "./SidebarFoodItem";
import { connect } from "react-redux";
import ACTIONS from "../redux/actions/recipe";
import FoodModal from "./FoodModal";
import _ from "lodash";
import PersonalMeal from "./PersonalMeal";

const { TabPane } = Tabs;
const { Search } = Input;

var mapStateToProps = (state) => {
  return {
    recipes: state.recipe.items,
    myMeals: state.meal.myMeals,
    user: state.auth.user.profile,
  };
};

class FoodSidebar extends Component {
  state = {
    recipes: [],
    searchVal: "",
    selectedItem: null,
    modalVisble: false,
    personalModal: false,
    isEdit: false,
  };
  // componentWillReceiveProps(nextProp) {
  //   if (nextProp.recipes.length > this.state.recipes.length) {
  //     this.setState({
  //       recipes: nextProp.recipes,
  //     });
  //   }
  // }

  filterRecipes(type = false) {
    let filteredRecipes = [];

    if (type == "mymeal") filteredRecipes = [...this.props.myMeals];
    else filteredRecipes = [...this.props.recipes, ...this.props.myMeals];

    if (this.state.searchVal) {
      filteredRecipes = filteredRecipes.filter((item) =>
        item.name.toLowerCase().includes(this.state.searchVal.toLowerCase())
      );
      filteredRecipes = _.uniq(filteredRecipes, "name");
    }

    if (type == "mymeal") return filteredRecipes;

    if (this.props.user.noOfMeals == 1) {
      filteredRecipes = filteredRecipes.filter((item) =>
        item.type.includes("OMAD")
      );
    } else if (this.props.user.noOfMeals == 2) {
      const temparr = filteredRecipes.filter((item) =>
        this.props.user.typeOfMeals.find((diet) => item.type.includes(diet))
      );
      let breadRecipes = filteredRecipes.filter((item) =>
        item.type.includes("Bread")
      );

      let dessertRecipes = filteredRecipes.filter((item) =>
        item.type.includes("Dessert")
      );
      filteredRecipes.push(...breadRecipes);
      filteredRecipes.push(...dessertRecipes);
      filteredRecipes.push(...temparr);
    }

    var additFats = this.props.recipes.filter((item) =>
      item.type.includes("AFI")
    );

    filteredRecipes.push(...additFats);

    if (type)
      filteredRecipes = filteredRecipes.filter((item) =>
        item.type.includes(type)
      );

    if (this.props.user.prefferedMeals.length > 0) {
      filteredRecipes = filteredRecipes.filter((item) =>
        this.props.user.prefferedMeals.find((diet) =>
          item.diet ? item.diet.includes(diet) : true
        )
      );
    }

    return filteredRecipes;
  }

  renderRecipeTypes(type) {
    var recipes = this.filterRecipes(type);

    return recipes.map((item) => (
      <SidebarFoodItem
        data={item}
        isPersonal={type == "mymeal" ? true : false}
        onClickAdd={(item) => {
          this.setState({
            selectedItem: {
              ...item,
              isPersonal: type == "mymeal" ? true : false,
            },
            modalVisble: true,
          });
          this.props.updateVisible();
        }}
        onClickEdit={(item) => {
          this.setState({
            selectedItem: item,
            personalModal: true,
            isEdit: true,
          });
          this.props.updateVisible();
        }}
      />
    ));
  }

  render() {
    var recipes = this.filterRecipes();
    console.log(this.state);
    return (
      <div
        className={`${styles.foodBankSidebar} ${
          this.props.visible ? styles.foodBankSidebarOpen : ""
        } `}
      >
        <PersonalMeal
          visible={this.state.personalModal}
          changeVisibility={() => this.setState({ personalModal: false })}
          selectedItem={this.state.selectedItem}
          isEdit={this.state.isEdit}
        />
        <FoodModal
          visible={this.state.modalVisble}
          changeVisibility={() =>
            this.setState({ modalVisble: false, selectedItem: false })
          }
          selectedItem={this.state.selectedItem}
        />
        <div
          className="ml-3 mt-3"
          style={{ cursor: "pointer" }}
          onClick={() => this.props.updateVisible()}
        >
          <CloseCircleOutlined
            style={{ fontSize: "1.8em", color: "#3d84ff" }}
          />
        </div>
        <Search
          placeholder="Search Foods"
          size="large"
          className="mx-3 mt-3"
          onSearch={(value) => this.setState({ searchVal: value })}
          style={{ width: "250px", borderRadius: "50px" }}
        />
        {this.props.visible ? (
          <Tabs
            defaultActiveKey={
              this.props.visible
                ? this.props.visible == "mymeal"
                  ? "8"
                  : "1"
                : "1"
            }
            className="mt-3"
            style={{ height: "85%", overflow: "scroll" }}
          >
            <TabPane tab="All" key="1">
              <div className="pb-3 px-3 ">
                <h3 className={styles.sidebarHeadings}>All Recipes</h3>
                {recipes.map((item) => (
                  <SidebarFoodItem
                    data={item}
                    onClickAdd={(item) => {
                      this.setState({ selectedItem: item, modalVisble: true });
                      this.props.updateVisible();
                    }}
                  />
                ))}
              </div>
            </TabPane>
            <TabPane tab="Breakfast" key="2">
              <div className="pb-3 px-3 ">
                <h3 className={styles.sidebarHeadings}>Breakfast</h3>
                {this.renderRecipeTypes("Breakfast")}
              </div>
            </TabPane>

            <TabPane tab="Lunch" key="3">
              <div className="pb-3 px-3 ">
                <h3 className={styles.sidebarHeadings}>Lunch</h3>
                {this.renderRecipeTypes("Lunch")}
              </div>
            </TabPane>
            <TabPane tab="Dinner" key="4">
              <div className="pb-3 px-3 ">
                <h3 className={styles.sidebarHeadings}>Dinner</h3>
                {this.renderRecipeTypes("Dinner")}
              </div>
            </TabPane>
            <TabPane tab="Bread" key="5">
              <div className="pb-3 px-3 ">
                <h3 className={styles.sidebarHeadings}>Bread</h3>
                {this.renderRecipeTypes("Bread")}
              </div>
            </TabPane>
            <TabPane tab="Dessert" key="6">
              <div className="pb-3 px-3 ">
                <h3 className={styles.sidebarHeadings}>Dessert</h3>
                {this.renderRecipeTypes("Dessert")}
              </div>
            </TabPane>
            <TabPane tab="AFI" key="7">
              <div className="pb-3 px-3 ">
                <h3 className={styles.sidebarHeadings}>AFI</h3>
                {this.renderRecipeTypes("AFI")}
              </div>
            </TabPane>
            <TabPane tab="My Meals" key="8">
              <div className="pb-3 px-3 ">
                <div className="d-flex">
                  <h3 className={styles.sidebarHeadings}>My Meals </h3>
                  <a
                    href="#"
                    style={{
                      fontWeight: "400",
                      fontSize: "13px",
                      marginLeft: "6.8em",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        personalModal: true,
                        selectedItem: null,
                        isEdit: false,
                      });
                      this.props.updateVisible();
                    }}
                  >
                    Add New
                  </a>{" "}
                </div>
                {this.renderRecipeTypes("mymeal")}
              </div>
            </TabPane>
          </Tabs>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(FoodSidebar);
