import React from "react";
import styles from "./mealview.module.css";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { DatePicker } from "antd";
import { Form, Input, Button, Checkbox, Radio, Select, message } from "antd";
import moment from "moment";
import apiRequest from "../data/clientRequest";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  CalendarOutlined,
  ShoppingCartOutlined,
  LoginOutlined,
  SearchOutlined,
  MenuOutlined,
  UserOutlined,
} from "@ant-design/icons";
import FoodSidebar from "./FoodSidebar";

var mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    meals: state.meal.items,
  };
};
const { Option } = Select;

function DashboardHeader(props) {
  const [sidebarVisible, setFoodSidebar] = React.useState(false);

  const onDateChange = (date, dateString) => {
    props.onCalenderChange(moment(dateString).format("MM DD YYYY"));
  };

  const createNewMealPlan = () => {
    apiRequest({
      url: "/newmealplans",
      method: "GET",
    })
      .then((res) => {
        props.fetchMealPlan();
        message.success("Please wait while we create the new Meal Plan");
      })
      .catch((err) => {
        message.error("You are not a paid user of the system");
        setTimeout(() => {
          window.localStorage.removeItem("authToken");
          window.location.href = "/";
        }, 2000);
      });
  };

  const mealDate =
    props.meals.length > 0 ? props.meals[props.meals.length - 1]["date"] : "";
  return (
    <nav
      className={`headerContainer ${styles.headContainer} navbar navbar-expand-lg`}
    >
      <a class="navbar-brand mr-5" href="#">
        <a>
          <img src="/loginlogo.png" style={{ width: "200px" }} alt="" />
        </a>
      </a>
      <div className="navbar-links-container">
        <h1 className={`${styles.orangeColor} ${styles.headingTitle}`}>
          Belvedere &nbsp;&nbsp;{" "}
          <span
            className={styles.blackColor}
            style={{ fontWeight: "400", fontSize: ".9em" }}
          >
            {moment().format("MMMM YYYY")}
          </span>
        </h1>
        <div className=" d-flex flex-wrap">
          <Select
            style={{ width: "140px" }}
            value={props.view}
            size="large"
            onChange={(val) => props.onChange(val)}
            className="viewSelector"
          >
            <Option value="single">Single Day</Option>
            <Option value="multi">Multi Day View</Option>
            {/* <Option value="weekly">Weekly View</Option> */}
          </Select>
          <Button
            size="large"
            className="ml-2"
            onClick={() => {
              props.onClickPrev();
            }}
          >
            <LeftOutlined />
          </Button>
          <Button
            size="large"
            className={`ml-2 ${styles.datePick}`}
            type="primary"
            onClick={() => {
              props.onClickToday();
            }}
          >
            Jump To Today
          </Button>
          <DatePicker
            onChange={onDateChange}
            className={`ml-2 ${styles.datePick}`}
          />
          <Button
            size="large"
            className="ml-2"
            onClick={() => {
              props.onClickNext();
            }}
          >
            <RightOutlined />
          </Button>
          {(moment().format("dddd") == "Friday" ||
            moment().format("dddd") == "Saturday") &&
          moment(mealDate).diff(moment(), "days") <= 2 ? (
            <Button
              size="large"
              className="ml-2"
              onClick={() => {
                createNewMealPlan();
              }}
            >
              Create New Meal Plan
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>

      <button
        style={{
          position: "absolute",
          right: "17px",
          top: "36px",
        }}
        class="navbar-toggler d-lg-none"
        type="button"
        data-toggle="collapse"
        data-target="#collapsibleNavId"
        aria-controls="collapsibleNavId"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <MenuOutlined />{" "}
      </button>
      <div class="collapse navbar-collapse" id="collapsibleNavId">
        <ul class="navbar-nav ml-auto mt-2 mt-lg-5">
          <div className="d-flex">
            <p
              className={`${styles.sidebarItem} ${styles.searchBtn}`}
              onClick={() => setFoodSidebar(true)}
            >
              <SearchOutlined /> Search Foods
            </p>
            <a
              onClick={() => setFoodSidebar("mymeal")}
              className={styles.sidebarItem}
            >
              <UserOutlined /> My Meals & Foods
            </a>
            <Link to="/dashboard" className={styles.sidebarItem}>
              <CalendarOutlined />
              Meal Planner
            </Link>
            <Link to="/dashboard/groceries" className={styles.sidebarItem}>
              <ShoppingCartOutlined />
              Groceries
            </Link>

            <Link to="/dashboard/profile" className={styles.sidebarItem}>
              <UserOutlined />
              Profile
            </Link>

            <FoodSidebar
              visible={sidebarVisible}
              updateVisible={() => setFoodSidebar(false)}
            />
            <p
              className={styles.sidebarItem}
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.localStorage.removeItem("authToken");
                window.location.href = "/";
              }}
            >
              <LoginOutlined /> Logout
            </p>
          </div>
        </ul>
      </div>
    </nav>
  );
}

export default connect(mapStateToProps, null)(DashboardHeader);
