import React from "react";
import styles from "./mealview.module.css";
import { Button } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { Popover } from "antd";

export default function SidebarFoodItem(props) {
  const {
    name,
    image,
    unit,
    fats,
    proteins,
    carbs,
    calories,
    servings,
    fiber,
    ingredients,
    sugaralcohol,
  } = props.data;
  const content = (
    <div>
      <p className="mb-2">PER 1 {unit}</p>
      <div
        className={`mb-0 d-flex justify-content-between ${styles.totalContain}`}
      >
        <span class="">Calories</span>
        <span class="">{calories}</span>
      </div>
      <div
        className={`mb-0 d-flex justify-content-between ${styles.totalContain}`}
        style={{ color: "#52C0BC" }}
      >
        <span class="">Fats</span>
        <span class="">{fats}</span>
      </div>
      <div
        className={`mb-0 d-flex justify-content-between ${styles.totalContain}`}
        style={{ color: "#976fe8" }}
      >
        <span class="">Proteins</span>
        <span class="">{proteins}</span>
      </div>
      <div
        className={`mb-0 d-flex justify-content-between ${styles.totalContain}`}
        style={{ color: "#FCB524" }}
      >
        <span class="">Carbs</span>
        <span class="">{carbs}</span>
      </div>

      <div
        className={`mb-0 d-flex justify-content-between ${styles.totalContain}`}
        style={{ color: "white" }}
      >
        <span class="">Carbs (Net)</span>
        <span class="">{Math.round(carbs - fiber - sugaralcohol)}</span>
      </div>
      <p className="mb-2">Total quantity from this recipe : {servings}</p>
      <div
        style={{ height: "1px", width: "100%", background: "gray" }}
        className="my-3"
      ></div>
      <div style={{ fontSize: "1.2em", fontWeight: "bold" }}>
        {ingredients &&
          ingredients.map((item) => {
            return (
              <div className="d-flex" style={{ fontSize: "0.8em" }}>
                <p className="mb-0">
                  {" "}
                  {item.amount} {item.unitOfMeasure}
                  &nbsp;{" "}
                  {item.ingredient
                    ? item.ingredient.name
                    : "Name not available"}
                </p>
              </div>
            );
          })}
      </div>
    </div>
  );

  return (
    <Popover content={content} title={name} placement="top" trigger="click">
      <div
        className={`d-flex mt-3 ${styles.foodSidebarItem} `}
        style={{ alignItems: "center" }}
      >
        <img
          className={styles.foodSidebarItemimage}
          src={`https://dashboard.leanwarriorweightloss.com/img/recipes/981ad0c0-b2f4-11ea-8606-5f86a24b03fe.png`}
          alt=""
        />
        <a className={styles.foodSidebarItemText}>{name}</a>
        {props.isPersonal ? (
          <Button
            onClick={() => props.onClickEdit(props.data)}
            className="mr-3"
            style={{
              lineHeight: "2px",
              width: "35px",
              textAlign: "center",
              padding: 0,
            }}
          >
            <EditOutlined style={{ fontWeight: "700", fontSize: "1.2em" }} />
          </Button>
        ) : (
          ""
        )}{" "}
        <Button
          onClick={() => props.onClickAdd(props.data)}
          style={{
            lineHeight: "2px",
            width: "35px",
            textAlign: "center",
            padding: 0,
          }}
        >
          <PlusOutlined style={{ fontWeight: "700", fontSize: "1.2em" }} />
        </Button>
      </div>
    </Popover>
  );
}
