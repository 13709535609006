import React, { Component } from "react";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Header from "../header";
import { connect } from "react-redux";
import ACTIONS from "../../redux/actions/auth";
import apiRequest from "../../data/clientRequest";
import { message } from "antd";

var mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    onboarding: state.auth.onboarding,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setUser: (item) => dispatch(ACTIONS.setUser(item)),
});
class Form extends Component {
  state = {
    currentStep: 0,
    firstStep: null,
    secondStep: null,
    thirdStep: null,
  };

  componentWillMount() {
    if (!this.props.onboarding) this.props.history.push("/");
  }

  onNext = async (value) => {
    console.log(value);
    let nextStep = this.state.currentStep + 1;

    if (this.state.currentStep == 0) this.setState({ firstStep: value });
    else if (this.state.currentStep == 1) {
      this.setState({ secondStep: value }, () => this.calculateNutrition());
    } else if (this.state.currentStep == 2) {
      this.setState({ thirdStep: value });
      await apiRequest({
        url: "/profile",
        method: "POST",
        data: Object.assign(
          {},
          this.state.firstStep,
          this.state.secondStep,
          value,
          { email: "" }
        ),
      })
        .then((res) => {
          message.success(res.data);
          apiRequest({
            url: "/me",
            method: "GET",
          }).then((resme) => {
            this.props.setUser(resme.data);
            this.props.history.push("/dashboard");
          });
        })
        .catch((err) => {
          message.error(err.data.msg);
        });
      return;
    }

    this.setState({ currentStep: nextStep });
  };

  calculateNutrition = () => {
    if (this.state.firstStep.unit == "imperial") {
      var height =
        (parseInt(this.state.firstStep.height) * 12 +
          parseInt(this.state.firstStep.hin)) *
        2.54;

      var weight = this.state.firstStep.weight / 2.2046;
    } else {
      var height = this.state.firstStep.height;
      var weight = this.state.firstStep.weight;
    }
    const { age, activityLevel } = this.state.firstStep;
    var activity = 0;
    if (activityLevel === "Sedentary") {
      activity = 1;
    } else if (activityLevel === "Moderately Active") {
      activity = 1.2;
    } else if (activityLevel === "Active") {
      activity = 1.3;
    }
    var tmr = (10 * weight + 6.25 * height - 5 * age - 161) * activity;

    if (this.state.secondStep.noOfMeals == 1) {
      var fats = Math.round(((tmr * 0.7) / 9) * 0.8);
      var proteins = Math.round(((tmr * 0.2) / 4) * 0.8);
      var carbs = Math.round(((tmr * 0.05) / 4) * 0.8);
    } else if (this.state.secondStep.noOfMeals == 2) {
      var fats = Math.round(((tmr * 0.7) / 9) * 0.9);
      var proteins = Math.round(((tmr * 0.2) / 4) * 0.9);
      var carbs = Math.round(((tmr * 0.05) / 4) * 0.9);
    } else if (this.state.secondStep.noOfMeals == 3) {
      var fats = Math.round((tmr * 0.7) / 9);
      var proteins = Math.round((tmr * 0.2) / 4);
      var carbs = Math.round((tmr * 0.05) / 4);
    }

    this.setState({ thirdStep: { fats, proteins, carbs, calories: tmr } });

    this.setState((prev) => ({
      firstStep: { ...prev.firstStep, height, weight },
    }));
  };

  onPrevious = () => {
    let prevStep = this.state.currentStep - 1;
    this.setState({ currentStep: prevStep });
  };

  renderStep = () => {
    if (this.state.currentStep == 0) {
      return <Step1 onNext={this.onNext} onPrevious={this.onPrevious} />;
    } else if (this.state.currentStep == 1) {
      return <Step2 onNext={this.onNext} onPrevious={this.onPrevious} />;
    } else if (this.state.currentStep == 2) {
      return (
        <Step3
          onNext={this.onNext}
          onPrevious={this.onPrevious}
          data={this.state.thirdStep}
        />
      );
    }
  };

  render() {
    return (
      <div className="container">
        <Header />
        <div className="row">
          <div class="col-md-4">
            <div
              class={`stepBtn ${this.state.currentStep == 0 ? "active" : ""}`}
            >
              <span class="textMuted">Step 1</span>
              <button>Physical profile</button>
            </div>
          </div>
          <div class="col-md-4">
            <div
              class={`stepBtn ${this.state.currentStep == 1 ? "active" : ""}`}
            >
              <span class="textMuted">Step 2</span>
              <button>Food choices</button>
            </div>
          </div>
          <div class="col-md-4">
            <div
              class={`stepBtn ${this.state.currentStep == 2 ? "active" : ""}`}
            >
              <span class="textMuted">Step 3</span>
              <button>Nutrition</button>
            </div>
          </div>
        </div>
        {this.renderStep()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
