import React from "react";
import styles from "./mealview.module.css";
import { connect } from "react-redux";

var mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

function MealTotals(props) {
  const [totals, setTotals] = React.useState({
    totalFats: 0,
    totalCarbs: 0,
    totalProteins: 0,
    totalCalories: 0,
    totalNetCarbs: 0,
    totalVeggies: 0,
    totalCards: 0,
  });

  React.useEffect(() => {
    var totalFats = 0;
    var totalCarbs = 0;
    var totalProteins = 0;
    var totalCalories = 0;
    var totalNetCarbs = 0;
    var totalVeggies = 0;
    var totalCards = 0;
    if (props.recipes.length > 0) {
      props.recipes.map((meal) => {
        if (meal.type == "Bread" && !props.user.profile.bread) return;
        if (meal.type == "Dessert" && !props.user.profile.dessert) return;

        meal.items.map((item) => {
          totalFats = totalFats + item.fats;
          totalCarbs += item.carbs;
          totalProteins += item.proteins;
          totalCalories += item.calories;
          totalNetCarbs += item.carbs - item.fiber - item.sugaralcohol;
          totalCards += item.carbs;
          totalVeggies += item.cupsofveggies * item.servings;
        });
      });
      setTotals({
        totalFats,
        totalCarbs,
        totalProteins,
        totalCalories,
        totalNetCarbs,
        totalVeggies,
        totalCards,
      });
    }
  }, [props.recipes]);

  return (
    <div className="col-md-6 mealTotals pt-md-5 pt-4">
      <div className="row mt-md-5 mt-4">
        <div className="col-md-6 pl-md-5 pl-sm-3 col-7">
          <h3 className="text-center mb-3" style={{ fontSize: "1em" }}>
            CURRENT TOTALS
          </h3>
          <div
            className={` d-flex justify-content-between ${styles.totalContain}`}
          >
            <span class="">Calories</span>
            <span class="">{parseFloat(totals.totalCalories).toFixed(2)}</span>
          </div>
          <div
            className={` d-flex justify-content-between ${styles.totalContain}`}
          >
            <span class="">Fats</span>
            <span class="">{parseFloat(totals.totalFats).toFixed(2)}</span>
          </div>
          <div
            className={` d-flex justify-content-between ${styles.totalContain}`}
          >
            <span class="">Proteins</span>
            <span class="">{parseFloat(totals.totalProteins).toFixed(2)}</span>
          </div>
          <div
            className={` d-flex justify-content-between ${styles.totalContain}`}
          >
            <span class="">Carbs (Total)</span>
            <span class="">{parseFloat(totals.totalCards).toFixed(2)}</span>
          </div>
          <div
            className={` d-flex justify-content-between ${styles.totalContain}`}
          >
            <span class="">Carbs (Net)</span>
            <span class="">{parseFloat(totals.totalNetCarbs).toFixed(2)}</span>
          </div>
          <div
            className={` d-flex justify-content-between ${styles.totalContain}`}
          >
            <span class="">Cups Of Veggies</span>
            <span class="">{parseFloat(totals.totalVeggies).toFixed(2)}</span>
          </div>
        </div>

        <div className="offset-md-1 col-md-4 col-5">
          <h3 className="text-center mb-3" style={{ fontSize: "1em" }}>
            TARGETS
          </h3>
          <div
            className={` d-flex justify-content-center ${styles.totalContain}`}
            style={{ color: "#fa52af" }}
          >
            {props.user.profile.calories.toFixed(2)}
          </div>
          <div
            className={` d-flex justify-content-center ${styles.totalContain}`}
            style={{ color: "#fa52af" }}
          >
            {parseFloat(props.user.profile.fats).toFixed(2)}g
          </div>

          <div
            className={` d-flex justify-content-center ${styles.totalContain}`}
            style={{ color: "#fa52af" }}
          >
            {parseFloat(props.user.profile.proteins).toFixed(2)}g
          </div>
          <div
            className={` d-flex justify-content-center ${styles.totalContain}`}
            style={{ color: "#fa52af" }}
          >
            -.--
          </div>
          <div
            className={` d-flex justify-content-center ${styles.totalContain}`}
            style={{ color: "#fa52af" }}
          >
            {parseFloat(props.user.profile.carbs).toFixed(2)}g
          </div>
          <div
            className={` d-flex justify-content-center ${styles.totalContain}`}
            style={{ color: "#fa52af" }}
          >
            6
          </div>
        </div>
      </div>
    </div>
  );
}
export default connect(mapStateToProps, null)(MealTotals);
