import React, { Component } from "react";

import { Form, Input, Button, Checkbox, Radio, Select } from "antd";
import { Row, Col } from "antd";
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};
export default class step2 extends Component {
  state = {
    selectedMeal: "anything",
    noOfMeals: 3,
  };
  onFinish = (values) => {
    this.props.onNext(values);
  };
  render() {
    return (
      <>
        <div class="container page-content">
          <Form
            {...layout}
            name="basic"
            initialValues={{ noMeals: 1 }}
            onFinish={this.onFinish}
          >
            <div class="signup_back"></div>
            <div class="signup_section_header col-12 text-center">
              <h2 class="display-6"> What are your daily Meals preferences?</h2>
            </div>
            <div class="step2">
              <div>
                <div class="row bottom_spacer">
                  <div class="col-12 content_container py-3">
                    <Form.Item
                      name="prefferedMeals"
                      label="Type Of Meals"
                      hasFeedback
                      rules={[
                        {
                          validator: (rule, value, callback) => {
                            if (value) {
                              if (value.length < 1) {
                                callback("Select a Prefferd Meal");
                              } else if (value.length >= 1) {
                                callback();
                              }
                            }
                            return;
                          },
                        },
                      ]}
                    >
                      <Select mode="multiple">
                        <Option value="Meat">Meat </Option>
                        <Option value="Vegan">Vegan</Option>
                        <Option value="Pescatarian">Pescatarian</Option>
                        <Option value="Vegetarian">Vegetarian</Option>
                      </Select>
                    </Form.Item>

                    <Form.Item name="noOfMeals" label="No Of Meals">
                      <Select
                        size="large"
                        onChange={(val) => this.setState({ noOfMeals: val })}
                      >
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                      </Select>
                    </Form.Item>

                    {this.state.noOfMeals == 2 ? (
                      <Form.Item
                        name="typeOfMeals"
                        label="Preferred Meals (Select two)"
                        hasFeedback
                        rules={[
                          {
                            validator: (rule, value, callback) => {
                              if (value) {
                                if (value.length > 2) {
                                  callback("No more than 2 meals");
                                } else if (value.length < 2) {
                                  callback("Select 2 Preffered Meals");
                                } else if (value.length <= 2) {
                                  callback();
                                }
                              }
                              return;
                            },
                          },
                        ]}
                      >
                        <Select size="large" mode="multiple">
                          <Option value="Breakfast">Breakfast</Option>
                          <Option value="Lunch">Lunch</Option>
                          <Option value="Dinner">Dinner</Option>
                        </Select>
                      </Form.Item>
                    ) : (
                      ""
                    )}

                    <Form.Item
                      name="bread"
                      label="Do you want to include Breads?"
                      valuePropName="checked"
                    >
                      <Checkbox></Checkbox>
                    </Form.Item>

                    <Form.Item
                      name="dessert"
                      label="Do you want to include Desserts?"
                      valuePropName="checked"
                    >
                      <Checkbox></Checkbox>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center">
              <button
                type="button"
                class="btn btn-large btn-orange mr-0 mr-md-3 mb-3 mb-md-0"
                onClick={this.props.onPrevious}
              >
                Previous step
              </button>

              <button type="submit" class="btn btn-large btn-orange">
                Next step
              </button>
            </div>
          </Form>
        </div>
      </>
    );
  }
}
