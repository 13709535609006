import ACTIONS from "../actions/meals";
import _ from "lodash";
import apiRequest from "../../data/clientRequest";
import { message } from "antd";
import moment from "moment";
var defaultState = {
  items: [],
  types: ["Breakfast", "Lunch", "Dinner"],
  groceries: [],
  myMeals: [],
};
var FOUR_WEEK_OLD = moment().subtract(28, "days").startOf("day");

function isWithin4Week(momentDate) {
  return momentDate.isAfter(FOUR_WEEK_OLD);
}
var authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ACTIONS.Types.setGroceries: {
      let newState = Object.assign({}, state);
      // To Set Groceries

      let tempList = state.items.filter((item) =>
        isWithin4Week(moment(item.date))
      );
      let groups = tempList.reduce((acc, item) => {
        const yearWeek = `${moment(item.date).year()}-${moment(
          item.date
        ).week()}`;

        if (!acc[yearWeek]) {
          acc[yearWeek] = [];
        }

        acc[yearWeek].push(moment(item.date).format("MM DD YYYY"));

        return acc;
      }, []);

      groups = Object.values(groups);
      let allRecipes = [];
      groups.map((currentWeekDates) => {
        let currentWeekPlan = tempList.filter((item) =>
          currentWeekDates.includes(moment(item.date).format("MM DD YYYY"))
        );

        let Recipes = currentWeekPlan.map((item) => {
          let tempObj = item.recipes.map((recipe) => recipe.items);
          tempObj = [].concat(...tempObj);

          tempObj = tempObj.filter((tempRec) => !tempRec["leftOver"]);

          const ingredients = tempObj.map((meal) => meal.ingredients);
          return ingredients;
        });

        Recipes = [].concat(...Recipes);
        Recipes = [].concat(...Recipes);
        Recipes = [].concat(...Recipes);
        Recipes = [].concat(...Recipes);
        Recipes = Recipes.filter(function (el) {
          return el != null;
        });
        Recipes = Recipes.map((item) => {
          let tempObj = {
            amount: item.amount,
            ...item.ingredient,
            unitOfMeasure: item.unitOfMeasure,
          };

          return tempObj;
        });

        allRecipes.push({ Recipes, dates: currentWeekDates });
      });

      newState.groceries = allRecipes;
      return newState;
    }

    case ACTIONS.Types.removeMeal: {
      let newState = Object.assign({}, state);
      let items = [...state.items];
      const { date, type, id } = action.payload;
      const foundIndex = items.findIndex((item) => item.date == date);
      let foundType = items[foundIndex].recipes.find(
        (item) => item.type == type
      );
      if (foundType) {
        items[foundIndex].recipes = items[foundIndex].recipes.map((ite) => {
          if (ite.type == type) {
            ite.items = ite.items.filter((ing) => ing.id !== id);
          }
          return ite;
        });
      }
      let tempItem = { ...items[foundIndex] };
      tempItem.date = moment(tempItem.date).format("MM DD YYYY");
      tempItem["recipes"] = tempItem.recipes.map((item) => {
        let temp = {
          ...item,
          recipeType: item.type,
        };
        return temp;
      });
      apiRequest({
        url: "/mealplan",
        method: "POST",
        data: {
          mealPlan: tempItem,
        },
      }).then((res) => {
        message.success("Meal Plan updated");
      });

      return { ...state, items };
    }

    case ACTIONS.Types.SET_MY_MEAL_PLANS: {
      let newState = Object.assign({}, state);
      newState.myMeals = action.payload;
      return newState;
    }

    case ACTIONS.Types.setMeals: {
      let newState = Object.assign({}, state);
      newState.items = action.payload;
      return newState;
    }

    case ACTIONS.Types.setServing: {
      let newState = Object.assign({}, state);
      let items = [...state.items];

      const { date, type, recipe, serving, id } = action.payload;
      if (recipe.servings > 1 && serving > recipe.servings)
        return { ...state, items };
      if (!serving) return { ...state, items };
      const foundIndex = items.findIndex((item) => item.date == date);
      let foundItem = items[foundIndex];
      let foundType = foundItem.recipes.find((item) => item.type == type);

      foundType.items = foundType.items.map((item) => {
        let selItem = { ...item };
        if (item.id == id) {
          selItem.fats = recipe.fats * serving;
          selItem.carbs = recipe.carbs * serving;
          selItem.proteins = recipe.proteins * serving;
          selItem.netCarbs = recipe.netCarbs * serving;
          selItem.sugaralcohol = recipe.sugaralcohol * serving;
          selItem.calories = recipe.calories * serving;
          selItem.fiber = recipe.fiber * serving;
          selItem.servings = serving;
          if (recipe.servings == 1 && recipe.ingredients)
            selItem.ingredients = recipe.ingredients.map((item) => {
              let tempObj = {
                ...item,
                amount: item.amount * serving,
              };
              return tempObj;
            });
        }
        return selItem;
      });

      foundItem.recipes = foundItem.recipes.map((ite) => {
        if (ite.type == type) {
          ite.items = foundType.items;
        }
        return ite;
      });

      let tempItem = { ...foundItem };
      tempItem.date = moment(tempItem.date).format("MM DD YYYY");
      tempItem["recipes"] = tempItem.recipes.map((item) => {
        let temp = {
          ...item,
          recipeType: item.type,
        };
        return temp;
      });
      items[foundIndex] = foundItem;

      apiRequest({
        url: "/mealplan",
        method: "POST",
        data: {
          mealPlan: tempItem,
        },
      }).then((res) => {
        message.success("Meal Plan updated");
      });
      return { ...state, items };
    }

    case ACTIONS.Types.addRecipe: {
      let newState = Object.assign({}, state);
      let items = [...state.items];
      message.info("Please wait while we save your Meal Plan");

      const { date, item, type } = action.payload;
      const foundIndex = items.findIndex(
        (item) => moment(item.date).format("MM DD YYYY") == date
      );
      const foundItem = items[foundIndex];
      let foundType = foundItem.recipes.find((item) => item.type == type);

      if (foundType) {
        foundItem.recipes = foundItem.recipes.map((ite) => {
          if (ite.type == type) {
            ite.items.push({ ...item });
          }
          return ite;
        });
      } else {
        foundItem["recipes"].push({
          items: [{ ...item }],
          type,
        });
      }
      let tempItem = { ...foundItem };
      tempItem.date = moment(tempItem.date).format("MM DD YYYY");
      tempItem["recipes"] = tempItem.recipes.map((item) => {
        let temp = {
          ...item,
          recipeType: item.type,
        };
        return temp;
      });

      apiRequest({
        url: "/mealplan",
        method: "POST",
        data: {
          mealPlan: tempItem,
        },
      }).then((res) => {
        message.success("Meal Plan updated");
      });
      items[foundIndex] = foundItem;

      return { ...state, items };
    }

    case ACTIONS.Types.calculateMeal: {
      const items = action.payload;
      return {
        ...state,
        items,
      };
    }

    case ACTIONS.Types.calculateMealDay: {
      const items = action.payload;
      return {
        ...state,
        items,
      };
    }
    default:
      return state;
  }
};
export default authReducer;
