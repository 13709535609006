import React, { Component } from "react";
import Header from "../components/header";
import { Form, Input, Button, Checkbox, Radio, Select, message } from "antd";
import { Row, Col } from "antd";
import apiRequest from "../data/clientRequest";
import ACTIONS from "../redux/actions/auth";
import { connect } from "react-redux";
import moment from "moment";
import FoodModal from "../components/FoodModal";

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};

var mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setUser: (item) => dispatch(ACTIONS.setUser(item)),
  setOnboarding: (item) => dispatch(ACTIONS.setOnboarding(item)),
});
class Login extends Component {
  state = { email: "" };

  logUserIn = (values) => {
    apiRequest({
      url: "/login",
      method: "POST",
      data: {
        email: values.email,
      },
    })
      .then((res) => {
        window.localStorage.setItem("authToken", res.data.token);
        console.log(res.data);
        apiRequest({
          url: "/me",
          method: "GET",
        }).then((resme) => {
          this.props.setUser(resme.data);
          if (res.data.newUser) {
            this.props.setOnboarding(true);
            this.props.history.push("/onboard");
          } else {
            this.props.history.push("/dashboard");
          }
        });
      })
      .catch((err) => {
        console.log(err);
        message.error(
          "You must have an active membership to the Lean Warrior Lifestyle program to access this application.  Contact support."
        );
      });
  };
  render() {
    return (
      <div
        className="container d-flex flex-direction-column"
        style={{
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Header />

        <div className="row">
          <div class="loginContainer text-center">
            <img style={{ width: "140px" }} src="/butlerLogo.png" alt="" />
            <p className="text-center">
              Belvedere Meal Planner…at your service
            </p>
            <Form
              {...layout}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={this.logUserIn}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <div class="text-center mb-3">
                <button
                  style={{ width: "150px" }}
                  type="submit"
                  class="btn btn-large btn-orange mt-4"
                  // onClick={() => this.props.onNext()}
                >
                  Logon
                </button>
              </div>

              <small className="text-center ">Version 1.4.0</small>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
