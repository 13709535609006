import React from "react";
import styles from "./mealview.module.css";
import { SyncOutlined, BarsOutlined } from "@ant-design/icons";
import moment from "moment";
export default function SingleDayHead(props) {
  const { date } = props;
  return (
    <div
      className="col-md-12"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        <h1
          className={`${styles.orangeColor} ${styles.headingTitle} ${styles.dayHeaderfirst}`}
        >
          {moment(date).format("dddd")}
        </h1>
        <h2
          className={`${styles.orangeColor} ${styles.headingTitle} ${styles.dayNumber}`}
        >
          {moment(date).format("D")}
        </h2>
      </div>
      <div className="d-flex">
        <small className="mr-4">
          {" "}
          Tap{" "}
          <SyncOutlined
            className={styles.mealBoxIcon}
            style={{ fontSize: "1em" }}
          />{" "}
          to generate, or search for foods to add them in.
        </small>
        <SyncOutlined
          style={{ fontSize: "1.6em", cursor: "pointer" }}
          onClick={() => props.onGenerateClick()}
        />
        <div className="mx-2"></div>
        {/* <BarsOutlined style={{ fontSize: "1.6em", cursor: "pointer" }} /> */}
      </div>
    </div>
  );
}
