import React, { Component } from "react";
import { Modal, Button, Row, Input, Col, Select, Checkbox } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { DatePicker, message } from "antd";
import styles from "./mealview.module.css";
import { connect } from "react-redux";
import ACTIONS from "../redux/actions/meals";
import moment from "moment";
const { Option } = Select;

var mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    meals: state.meal.items,
  };
};

var mapDispatchToProps = (dispatch) => ({
  addRecipe: (item) => dispatch(ACTIONS.addRecipe(item)),
});

class FoodModal extends Component {
  state = {
    selectedDate: "",
    mealType: "",
    noOfServing: "1",
    isLeftOver: false,
  };

  componentWillReceiveProps(nextProp) {
    if (nextProp.selectedItem)
      if (nextProp.selectedItem.type.includes("Bread")) {
        this.setState({ mealType: "Bread" });
      } else if (nextProp.selectedItem.type.includes("AFI")) {
        this.setState({ mealType: "AFI" });
      } else if (nextProp.selectedItem.type.includes("Dessert")) {
        this.setState({ mealType: "Dessert" });
      }
  }

  handleOk = (e) => {
    if (!this.state.selectedDate) {
      message.error("Please select a Date");
      return;
    }
    if (!this.state.mealType) {
      message.error("Please select a meal type");
      return;
    }

    let noOfServing = parseInt(this.state.noOfServing);

    const { isLeftOver } = this.state;
    console.log(this.props.selectedItem);
    let selItem = Object.assign({}, this.props.selectedItem);
    selItem.fats = selItem.fats * noOfServing;
    selItem.carbs = selItem.carbs * noOfServing;
    selItem.proteins = selItem.proteins * noOfServing;
    selItem.netCarbs = selItem.netCarbs * noOfServing;
    selItem.sugaralcohol = selItem.sugaralcohol * noOfServing;
    selItem.calories = selItem.calories * noOfServing;
    selItem.fiber = selItem.fiber * noOfServing;
    selItem.origRecipe = selItem.servings;
    if (selItem.servings == 1 && selItem.ingredients)
      selItem.ingredients = selItem.ingredients.map((item) => {
        let tempObj = {
          ...item,
          amount: item.amount * noOfServing,
        };
        return tempObj;
      });
    selItem.servings = noOfServing;

    console.log(selItem);
    if (this.state.isLeftOver) selItem.leftOver = true;
    this.props.changeVisibility(false);
    // setTimeout(() => {
    this.props.addRecipe({
      item: selItem,
      date: this.state.selectedDate,
      type: this.state.mealType,
    });
    // }, 1000);
  };

  handleCancel = (e) => {
    this.props.changeVisibility(false);
  };
  disabledDate = (current) => {
    // Can not select days after today and before start Date
    const start = moment(this.props.meals[0].date, "MM DD YYYY");
    const end = moment(
      this.props.meals[this.props.meals.length - 1].date,
      "MM DD YYYY"
    );

    return current < start || current > end;
  };

  render() {
    console.log(this.props.selectedItem);

    return (
      <Modal
        title="Add item to a meal
        "
        visible={this.props.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={this.handleOk}
            style={{ display: "flex", alignItems: "center" }}
          >
            <PlusCircleOutlined style={{ fontSize: "1.2em" }} />
            Add
          </Button>,
        ]}
        className="foodPopup"
      >
        <div className="p-0">
          <Row style={{ alignItems: "center" }}>
            <Col span={4} offset={1} className="mr-4">
              <img
                src={`https://dashboard.leanwarriorweightloss.com/img/recipes/${
                  this.props.selectedItem && this.props.selectedItem.image
                    ? this.props.selectedItem.image
                    : "981ad0c0-b2f4-11ea-8606-5f86a24b03fe.png"
                }`}
                alt=""
                style={{
                  height: "100px",
                  width: "100px",
                  borderRadius: "15px",
                  border: "2px solid gainsboro",
                }}
              />
            </Col>
            <Col
              span={18}
              xs={24}
              md={18}
              className="mt-3 mt-md-0 modalInputContainer"
            >
              <h2
                className={styles.foodSidebarItemText}
                style={{ display: "block" }}
              >
                {this.props.selectedItem ? this.props.selectedItem.name : ""}
              </h2>
              <DatePicker
                disabledDate={this.disabledDate}
                size="large"
                placeholder={"Select Meal Day"}
                format={"MM DD YYYY"}
                className={`ml-2 mr-2`}
                style={{ width: "160px" }}
                // defaultValue={moment()}
                onChange={(date, dateString) =>
                  this.setState({ selectedDate: dateString })
                }
              />
              <Select
                style={{ width: "160px" }}
                placeholder={"Select Meal Type"}
                defaultValue="Breakfast"
                value={this.state.mealType}
                size="large"
                onChange={(val) => this.setState({ mealType: val })}
              >
                <Option value="Breakfast">Breakfast</Option>
                <Option value="Lunch">Lunch</Option>
                <Option value="Dinner">Dinner</Option>
              </Select>
              <Input
                className={"ml-2"}
                size="large"
                style={{ width: "160px" }}
                value={this.state.noOfServing}
                onChange={(e) => this.setState({ noOfServing: e.target.value })}
                placeholder="No OF Servings"
              />
              <div className="ml-2 mt-3">
                <Checkbox
                  onChange={(e) =>
                    this.setState({ isLeftOver: e.target.checked })
                  }
                >
                  Is Leftover ?
                </Checkbox>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FoodModal);
