import React from "react";
import styles from "./mealview.module.css";
import {
  SyncOutlined,
  BarsOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { Input } from "antd";
import { connect } from "react-redux";
import ACTIONS from "../redux/actions/meals";
import { Popover } from "antd";
import { Spin, Tooltip, message } from "antd";
import LeftoverModal from "./LeftoverModal";

var mapDispatchToProps = (dispatch) => ({
  setServing: (item) => dispatch(ACTIONS.setServing(item)),
  setGroceries: (item) => dispatch(ACTIONS.setGroceries(item)),
  setRemoveMeal: (item) => dispatch(ACTIONS.setRemoveMeal(item)),
});

var mapStateToProps = (state) => {
  return {
    recipes: state.recipe.items,
    myMeals: state.meal.myMeals,
  };
};

function MealBox(props) {
  const { meals } = props;
  const [loading, setLoading] = React.useState(false);
  const [leftModal, setLeftModal] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(false);
  const [totals, setTotals] = React.useState({
    totalFats: 0,
    totalCarbs: 0,
    totalProteins: 0,
    totalCalories: 0,
    totalNetCarbs: 0,
  });

  React.useEffect(() => {
    var totalFats = 0;
    var totalProteins = 0;
    var totalCalories = 0;
    var totalNetCarbs = 0;
    if (props.recipes.length > 0) {
      props.meals.map((meal) => {
        meal.items.map((item) => {
          totalFats = totalFats + item.fats;
          totalProteins += item.proteins;
          totalCalories += item.calories;
          totalNetCarbs += item.carbs - item.fiber - item.sugaralcohol;
        });
      });
      setTotals({
        totalFats,
        totalProteins,
        totalNetCarbs,
        totalCalories,
      });
    }
  }, [props.meals]);

  return (
    <div className={`${styles.mealBoxContainer} col-md-12`}>
      <div
        className="d-flex justify-content-between mb-3"
        style={{ alignItems: "center" }}
      >
        <div>
          <p className={`${styles.breakHeading} mb-0`}>{props.type}</p>
          <p>
            {parseFloat(totals.totalCalories).toFixed(2)} Calories,
            {parseFloat(totals.totalFats).toFixed(2)}g Fats, <br />
            {parseFloat(totals.totalProteins).toFixed(2)}g Proteins,
            {parseFloat(totals.totalNetCarbs).toFixed(2)}g Net Carbs
          </p>
        </div>
        <LeftoverModal
          visible={leftModal}
          changeVisibility={() => setLeftModal(false)}
          selectedItem={selectedItem}
        />
        <div className="d-flex">
          <Spin
            indicator={
              loading ? (
                <SyncOutlined
                  spin
                  className={styles.mealBoxIcon}
                  style={{ cursor: "pointer" }}
                  onClick={async () => {
                    setLoading(true);
                    await props.onClickGenerate(props.type);
                    setLoading(false);
                  }}
                />
              ) : (
                <SyncOutlined
                  className={styles.mealBoxIcon}
                  style={{ cursor: "pointer" }}
                  onClick={async () => {
                    setLoading(true);
                    await props.onClickGenerate(props.type);
                    setLoading(false);
                  }}
                />
              )
            }
          />
          <div className="mx-2"></div>
        </div>
      </div>
      {meals.length == 0 || (meals.length > 0 && meals[0].items.length == 0) ? (
        <div>
          <p className="mb-1 font-weight-bold">EMPTY MEAL</p>
          <p>
            Tap{" "}
            <SyncOutlined
              className={styles.mealBoxIcon}
              style={{ fontSize: "1em" }}
            />{" "}
            to generate, or search for foods to add them in.
          </p>
        </div>
      ) : (
        meals.map((meal) => {
          return meal.items.map((item) => {
            const {
              name,
              image,
              servingSize,
              unit,
              fats,
              proteins,
              carbs,
              calories,
              servings,
              ingredients,
              fiber,
              sugaralcohol,
              origRecipe,
              leftOver,
            } = item;

            const content = (
              <div>
                <p className="mb-2">
                  PER {servings * servingSize} {unit}
                </p>
                <div
                  className={`mb-0 d-flex justify-content-between ${styles.totalContain}`}
                >
                  <span class="">Calories</span>
                  <span class="">{calories}</span>
                </div>
                <div
                  className={`mb-0 d-flex justify-content-between ${styles.totalContain}`}
                  style={{ color: "#52C0BC" }}
                >
                  <span class="">Fats</span>
                  <span class="">{fats}</span>
                </div>
                <div
                  className={`mb-0 d-flex justify-content-between ${styles.totalContain}`}
                  style={{ color: "#976fe8" }}
                >
                  <span class="">Proteins</span>
                  <span class="">{proteins}</span>
                </div>
                <div
                  className={`mb-0 d-flex justify-content-between ${styles.totalContain}`}
                  style={{ color: "#FCB524" }}
                >
                  <span class="">Carbs</span>
                  <span class="">{carbs}</span>
                </div>
                <div
                  className={`mb-0 d-flex justify-content-between ${styles.totalContain}`}
                  style={{ color: "white" }}
                >
                  <span class="">Carbs (Net)</span>
                  <span class="">
                    {Math.round(carbs - fiber - sugaralcohol)}
                  </span>
                </div>

                <p className="mb-2">
                  Total quantity from this recipe : {origRecipe}
                </p>
                <div
                  style={{ height: "1px", width: "100%", background: "gray" }}
                  className="my-3"
                ></div>
                <div style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                  {ingredients &&
                    ingredients.map((item) => (
                      <div className="d-flex" style={{ fontSize: "0.8em" }}>
                        <p className="mb-0">
                          {" "}
                          {parseFloat(item.amount).toFixed(2)}{" "}
                          {item.unitOfMeasure ? item.unitOfMeasure : " "}
                          &nbsp; {item.ingredient.name}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            );

            let popContent = "";

            if (origRecipe > 1 && !item["leftOver"]) {
              popContent = (
                <div>
                  <div>
                    <a>Create Leftovers Food</a>
                  </div>
                </div>
              );
            }

            return (
              <div
                class={`mb-1  d-flex align-items-center p-3  ${styles.mealBox}`}
              >
                <Popover
                  content={content}
                  title={item.name}
                  className="d-flex align-items-center w-100"
                  placement="top"
                  trigger="click"
                >
                  <img
                    class="mealImg"
                    src={`https://dashboard.leanwarriorweightloss.com/img/recipes/981ad0c0-b2f4-11ea-8606-5f86a24b03fe.png`}
                    alt=""
                  />
                  <div className={styles.foodTitle}>
                    <h4 class="m-0">
                      {item.name}{" "}
                      <small style={{ color: "#fa52af" }}>
                        {leftOver ? "Leftover" : ""}
                      </small>
                    </h4>
                    <span>
                      <span className="d-inline-block ">
                        <MinusCircleOutlined
                          style={{ fontSize: "1.5em" }}
                          onClick={() => {
                            if (servings > 0) {
                              let tempData = {
                                serving: servings - 0.25,
                                type: meal.type,
                                id: item.id,
                                date: props.date,
                                recipe: {
                                  ...props.recipes.find(
                                    (rec) => rec.id === item.id
                                  ),
                                },
                              };
                              console.log(props.recipes);
                              console.log(item);
                              console.log(tempData);
                              if (!tempData.recipe) {
                                tempData.recipe = props.myMeals.find(
                                  (rec) => rec.id == item.id
                                );
                              }

                              if (tempData.recipe) props.setServing(tempData);
                            }
                          }}
                        />
                      </span>
                      <span
                        className="d-inline-block mx-3 text-center"
                        style={{ fontSize: "1.2em", width: "35px" }}
                      >
                        {servings}
                      </span>
                      <span className="d-inline-block mr-3">
                        <PlusCircleOutlined
                          style={{ fontSize: "1.5em" }}
                          onClick={() => {
                            if (
                              origRecipe == 1 ||
                              servings < origRecipe / servingSize
                            ) {
                              let tempData = {
                                serving: servings + 0.25,
                                type: meal.type,
                                id: item.id,
                                date: props.date,
                                recipe: {
                                  ...props.recipes.find(
                                    (rec) => rec.id == item.id
                                  ),
                                },
                              };
                              if (!tempData.recipe) {
                                tempData.recipe = props.myMeals.find(
                                  (rec) => rec.id == item.id
                                );
                              }

                              if (tempData.recipe) props.setServing(tempData);
                            } else
                              message.error("That's the max for this recipe");
                          }}
                        />
                      </span>

                      {item.unit}
                    </span>
                  </div>
                  <div className={`ml-auto  ${styles.closeContainer}`}>
                    <CloseCircleOutlined
                      onClick={() => {
                        props.setRemoveMeal({
                          type: meal.type,
                          id: item.id,
                          date: props.date,
                        });
                      }}
                      style={{ fontSize: "2em", color: "indianred" }}
                    />
                    {popContent ? (
                      <Tooltip title="Create Leftovers">
                        <BarsOutlined
                          onClick={() => {
                            setLeftModal(true);
                            setSelectedItem(item);
                          }}
                          style={{
                            fontSize: "1.6em",
                            cursor: "pointer",
                            marginLeft: "7px",
                          }}
                        />
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </div>
                </Popover>
              </div>
            );
          });
        })
      )}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MealBox);
