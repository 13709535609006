import axios from "axios";
import moment from "moment";
const apiRequest = function (options) {
  const authToken = window.localStorage.getItem("authToken");

  const onSuccess = (response) => {
    // log each request response
    return response;
  };
  const onError = function (error) {
    if (error.response)
      // some error happened with the server side
      console.log(error.response);
    // some error happened while processing the request
    else console.error("Error Message:", error.message);
    return Promise.reject(error.response || error.message);
  };
  return axios({
    baseURL: "https://dashboard.leanwarriorweightloss.com/api/",
    ...options,
    headers: {
      "x-auth-token": `${authToken}`,
      "x-date": moment().format("MM/DD/YYYY"),
    },
  })
    .then(onSuccess)
    .catch(onError);
};
export default apiRequest;
