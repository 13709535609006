import React from "react";
import styles from "./mealview.module.css";
import { SyncOutlined, BarsOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { connect } from "react-redux";
import ACTIONS from "../redux/actions/meals";

var mapDispatchToProps = (dispatch) => ({
  setServing: (item) => dispatch(ACTIONS.setServing(item)),
});

var mapStateToProps = (state) => {
  return {
    recipes: state.recipe.items,
  };
};

function GroceryBox(props) {
  const { item } = props;
  return (
    <div
      className={`${styles.foodTitle} d-flex`}
      style={{
        padding: "0.7em",
      }}
    >
      <h4 class="m-0 mr-2">{item.name}</h4>
      <span>
        {item.amount} {item.unit}
      </span>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(GroceryBox);
